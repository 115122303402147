import React, {useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import {ApolloProvider} from "@apollo/react-hooks";
import App from "./App";
import Login from "./components/auth/Login";
import {PrivateRoute} from "./routers/PrivateRoute";
import {SigninRoute} from "./routers/SigninRoute";
import {client} from "./graphql/graphqlConnect";
import { ME } from "./graphql/queries";
import { MeContext } from "./MeContext";
import EmailConfirmation from "./components/auth/EmailConfirmation/EmailConfirmation";
import SignUp from "./components/auth/SignUp";
import ResetPassword from "./components/auth/ResetPassword";
import ConfirmPassword from "./components/auth/ConfirmPassword";
import JiraCallback from "./components/auth/JiraCallback";
import EventCategories from "./components/body/events/EventCategories";
import Events from "./components/body/events/Events"
import { Profile } from "./components/profile/Profile";
import Event from "./components/body/events/Event";


const getMe = async ()  => {
    try {
        const req : any = await client.query({query: ME});
        const res : any = await req;
        return res.data;
    }catch(e) {
        console.warn(e);
        return null;
    }   
}

export default function Routers(){
    const [me, setMe] = useState<any | null>(null);
    
    useEffect(() => {
        getMe()
        .then((data : any) => {
            setMe(data.me);
            localStorage.setItem("myEmail", JSON.stringify(data.me.email.emailStr))
        }
        )
        .catch((e : Error) => {
            console.warn(e);
            setMe(null);
        })

    }, [])

    return(
        <MeContext.Provider value={me}>
        <ApolloProvider client={client}> 
        <Router>
            <Switch>
            <PrivateRoute path="/" component={App} exact />
            <SigninRoute path="/login" component={Login} restricted={true} exact />
            <Route path="/my-profile" component={Profile} exact />
            <Route path="/event_categories" component={EventCategories} />
            <Route path="/confirmation/:token"  component={EmailConfirmation} />
            <Route path="/signup" component={SignUp} />
            <Route path="/reset_password" component={ResetPassword} exact/>
            <Route path="/reset_password/:token" component={ConfirmPassword}/>
            <Route path="/callback/jira_code" component={JiraCallback} />
            <Route path="/events/:eventCategory" component={Events} exact />
            <Route path="/events/:eventCategory/:eventId" component={Event} />
            </Switch>
        </Router>
        
        </ApolloProvider>
        </MeContext.Provider>
    )
}