import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { MeContext } from '../MeContext';

export const PrivateRoute : any = ({component: Component, ...rest}: any) => {
    const me = useContext(MeContext);

    const isAuthenticated  = () => {
        if(me !== null) return true
        else return false
    }
    
    return (
        
        <Route {...rest} render={props => (
            isAuthenticated() ?
                <Component {...props} />
            : <Redirect to="/login" />
        )} />
    );
};