import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
const images = [
  "https://scolution.de/wp-content/uploads/2021/08/API-Token-Blog_Schritt-3.png",
  "https://scolution.de/wp-content/uploads/2021/08/API-Token-Blog_Schritt-4.png",
  "https://scolution.de/wp-content/uploads/2021/08/API-Token-Blog_Schritt-5.png",
];

const zoomOutProperties = {
    duration: 15000,
    transitionDuration: 500,
    infinite: true,
    scale: 0.4,
    arrows: true,
  };

export default function ImageSlider() {
    return (
        <div className="slide-container" style={{maxWidth: "500px", width: "500px"}}>
        <Zoom {...zoomOutProperties}>
          {images.map((each, index) => (
            <img key={index} style={{ width: "100%" }} src={each} alt="token_gen" />
          ))}
        </Zoom>
      </div>
    )

}
