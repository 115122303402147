import gql from "graphql-tag";

const GET_DEPARTMENTS = gql`
  {
    departments {
      _id
      name
    }
  }
`;
const ME = gql`
  {
    me {
      _id
      name
      department
      admin
      approved
      profileImg
      email {
        emailStr
      }
      jira {
        filter
        token
      }
    }
  }
`;
const USERS = gql`
  {
    users {
      _id
      name
      department
      approved
      admin
      email {
        emailStr
      }
    }
  }
`;
const TODAYS_TODO = gql`
  query todaysTodo($date: String!) {
    todaysTodo(date: $date) {
      _id
      username
      department
      name
      userImg
      date
      todos {
        _id
        text
        checked
      }
    }
  }
`;
const JOIN_REQUESTS = gql`
  {
    joinRequests {
      _id
      user {
        _id
        department
        name
        email {
          emailStr
        }
      }
    }
  }
`;
const GET_JIRA_ISSUES = gql`
  query getJiraIssues( $jiraFilter: String!) {
    getJiraIssues(jiraFilter: $jiraFilter) {
      id
      title
      priority
    }
  }
`;
const GET_FAVOURITE_FILTERS = gql`
  query getFavouriteFilter {
    getFavouriteFilter {
      name
      jql
      url
    }
  }
`;
const GET_WEEKLY_TODOS = gql`
  query getWeeklyTodos($from: String!, $to: String!) {
    getWeeklyTodos(from: $from, to: $to) {
      _id
      date
      username
      name
      userImg
      todos {
        _id
        text
        checked
      }
    }
  }
`;
const EVENT_CATEGORIES = gql`
  {
    eventCategories {
      _id
      title
      img
    }
  }
`;
const EVENTS = gql`
  query events($eventCategoryName: String!) {
    events(eventCategoryName: $eventCategoryName) {
      _id
      title
      text
      creator{
        name
        email
        profileImg
      }
      category
      date
      toDate
      img
      participants {
        _id
        name
        username
        profileImg
      }
    }
  }
`;
const GET_EVENT = gql`
query event($eventId: ID!){
  event(eventId: $eventId){
    _id
      title
      text
      creator{
        name
        email
        profileImg
      }
      category
      date
      toDate
      img
      participants {
        _id
        name
        username
        profileImg
      }
  }
}
`

const GET_NOTIFICATIONS = gql`
query getNotificationsQuery($email: String!){
  getNotifications(email: $email){
      _id
      title
      watched
      to
      url
      date
      from{
        name 
        profileImg
        email
      }
  }
}
`;


const GET_EVENT_COMMENTS = gql`
 query getEventComments($eventId: String!){
  getEventComments(eventId: $eventId){
    _id
     commenter {
       username
       showName
       profileImg
     }
     eventId
     text
   }
 }
`
const GET_JIRA_CALLBACK_URL = gql`
query getJiraCallbackUrl{
  getJiraCallbackUrl
}
`
export {
  GET_DEPARTMENTS,
  ME,
  JOIN_REQUESTS,
  TODAYS_TODO,
  USERS,
  GET_JIRA_ISSUES,
  GET_FAVOURITE_FILTERS,
  GET_WEEKLY_TODOS,
  EVENT_CATEGORIES,
  EVENTS,
  GET_NOTIFICATIONS,
  GET_EVENT_COMMENTS,
  GET_JIRA_CALLBACK_URL,
  GET_EVENT
};
