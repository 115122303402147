import React, {useEffect} from "react";
import ViewSettings from "./todoWeekoverview/ViewSettings";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";

export default function DatePacker(props: any) {
  const [selectedDate, setSelectedDate] = React.useState(new Date(props.date));
  const handleChange = (event: any) => {
    const newDate= new Date(event)
    props.dateHandle(moment(newDate).format("YYYY-MM-DD"));
  };
  useEffect(() => {
    setSelectedDate(props.date)
  },[handleChange])
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        marginTop: "50px",
      }}
>
<MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Datum"
          value={selectedDate}
          onChange={handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
      {/* <TextField
        type="date"
        variant="outlined"
        value={props.date}
        onChange={handleChange}
      /> */}
      <ViewSettings />
    </div>
  );
}
