import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CONFIRM_EMAIL } from "../../../graphql/mutations";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "./email-confimration.css";
import logo from "./../../../img/logo.png";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function ConfirmMsg() {
  return (
    <div className="email-confirm-box">
      <img src={logo} alt="logo" />      
      <h1>Glückwunsch</h1>
      <p>
        Ihre E-Mail wurde bereits bestätigt. Sie können sich jetzt bei der
        Main Seite anmelden
      </p>
      <CheckCircleIcon style={{color: "#01C854", fontSize:"35px"}}/>
      <br />
       <a href="/login">Zurück zum Login-Seite </a>
    </div>
  );
}
function ErrorMsg() {
  return (
    <div className="email-confirm-box">
      <img src={logo} alt="logo"/>      
      <h2>Ihre E-mail konnte nicht bestätigt werden</h2>
      <p>
        Versuchen sie nochmal
      </p>
      <HighlightOffIcon style={{color: "red", fontSize:"35px"}}/>
      <br />
       <a href="/login">Zurück zum Login- </a>
    </div>
  )
}

export default function EmailConfirmation() {
  const [confirmed, setConfirmed] = useState(false);
  const params: any = useParams();
  const [confirmEmail] = useMutation(CONFIRM_EMAIL, {
    variables: { token: params.token },
  });
  const isConfirmed: any = async () => {
    const res = await confirmEmail();

    if (res.data.confirmEmail) {
      setConfirmed(true);
      return true;
    } else {
      setConfirmed(false);
      return false;
    }
  };
  useEffect(() => {
    isConfirmed();
  }, []);

  return (
    <div>
      {confirmed ? <ConfirmMsg /> : <ErrorMsg/>}
    </div>
  );
}
