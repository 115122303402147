import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { Route, Redirect } from 'react-router-dom';
import { MeContext } from "../MeContext";



export const SigninRoute : any = ({component: Component, restricted, ...rest} : any) => {
    const me = useContext(MeContext);
    
   const isAuthenticated  = () => {
       if(me !== null) return true
       else return false
   }
    return (

        <Route {...rest} render={props => (
            isAuthenticated() && restricted ?
                <Redirect to="/" />
            : <Component {...props} />
        )} />
    );
};