import { useState, useRef, useContext } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Badge from "@material-ui/core/Badge";
import {
  MenuList,
  MenuItem,
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  Tooltip,
  makeStyles,
  Avatar,
  Divider,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { MeContext } from "../../MeContext";
import { GET_NOTIFICATIONS, JOIN_REQUESTS } from "../../graphql/queries";
import {
  ANSWER_JOIN_REQUEST,
  UPDATE_NOTIFICATIONS,
} from "../../graphql/mutations";
import CircularProgress from "@material-ui/core/CircularProgress";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { Typography } from "@mui/material";

const useStyles: any = makeStyles((theme) => ({
  notificationIcon: {
    color: "silver",
    height: "25px",
    width: "25px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "grey",
      backgroundOpacity: "0.5%",
      transition: "0.5s",
      borderRadius: "200px",
    },
  },
  notPaper: {
    maxHeight: "250px",
    border: "1px solid silver",
    overflow: "auto",
    "-webkit-box-shadow": "0 5px 10px -5px silver",
    "-moz-box-shadow": "0 5px 10px -5px silver",
    " box-shadow": "0 5px 10px -5px silver",
  },
  joinReq: {
    width: "250px",
  },
  btn: {
    cursor: "pointer",
  },
}));
export default function Notification() {
  const classes = useStyles();
  const [answerJoinRequest, {}] = useMutation(ANSWER_JOIN_REQUEST);
  const [open, setOpen] = useState(false);
  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS);
  const me: any = useContext(MeContext);
  const { data, loading, error } = useQuery(GET_NOTIFICATIONS, {
    variables: { email: me.email.emailStr },
    pollInterval: 5000,
  });

  const { data: joinRequestData = { joinRequests: [] }, refetch } =
    useQuery(JOIN_REQUESTS, {pollInterval: 250000},);
    
  const prevOpen = useRef(open);
  const anchorRef = useRef(null);

  const handleClose = () => {
    const ids = data.getNotifications.map((n: any) => {
      return n._id;
    });
    const setNotificationWatched = updateNotifications({ variables: { ids } });

    // @ts-ignore: Object is possibly 'null'.

    if (anchorRef.current && anchorRef.current.contains(Event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleAcceptUser = async (item: any) => {
    const res = await answerJoinRequest({
      variables: {
        _id: item._id,
        userId: item.user._id,
        answer: true,
      },
    });
    refetch();
    setOpen(false);
  };
  const handleRejectUser = async (item: any) => {
    const res = await answerJoinRequest({
      variables: {
        _id: item._id,
        userId: item.user._id,
        answer: false,
      },
    });
    refetch();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const getNotificationsNum = (notifications: any, joinRequests: any) => {
    let notCounter: number = 0;
    let joinReqCounter: number = 0;

    joinRequests.map((j: any) => joinReqCounter++);

    notifications.filter((n: any) => {
      if(n.from.email !== me.email.emailStr) return n
    }).map((n: any) => (n.watched ? notCounter : notCounter++));

    if (me.admin) return notCounter + joinReqCounter;
    return notCounter;
  };
  const isNotificationsEmpty = () : boolean => {
    let notifications = data.getNotifications.filter((n: any) => {
      if (n.from.email !== me.email.emailStr) return n;
    })
    if(notifications.length === 0) return true
    return false
  }
  if (loading) return <CircularProgress />;
  
  return (
    <>
      {error ? (
        <NotificationsOffIcon className={classes.notificationIcon} />
      ) : (
        <Badge
          badgeContent={getNotificationsNum(
            data.getNotifications,
            joinRequestData.joinRequests
          )}
          color="error"
        >
          <NotificationsIcon
            className={classes.notificationIcon}
            ref={anchorRef}
            onClick={handleToggle}
          />
        </Badge>
      )}
      <Popper open={open} anchorEl={anchorRef.current} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              marginTop: "30px",
            }}
          >
            <Paper className={classes.notPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="menu-list-grow"
                  style={{ height: "auto", width: "100%" }}
                >
                  {me.admin
                    ? joinRequestData.joinRequests.map(
                        (item: any, key: any) => {
                          return (
                            <>
                              <MenuItem key={key}>
                                <h3>{item.user.name} möchte Tazi beitreten</h3>
                                <div
                                  className={classes.joinReq}
                                  style={{ display: me.admin ? "" : "none" }}
                                >
                                  <p>{item.user.email.emailStr}</p>
                                  <p>{item.user.department}</p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Tooltip title="Annehmen">
                                      <CheckCircleIcon
                                        onClick={() => handleAcceptUser(item)}
                                        className={classes.btn}
                                        style={{ color: "green" }}
                                      ></CheckCircleIcon>
                                    </Tooltip>
                                    <Tooltip title="Ablehnen">
                                      <CancelIcon
                                        className={classes.brn}
                                        onClick={() => handleRejectUser(item)}
                                        color="secondary"
                                      >
                                        ablehnen
                                      </CancelIcon>
                                    </Tooltip>
                                  </div>
                                </div>
                              </MenuItem>
                              <Divider />
                            </>
                          );
                        }
                      )
                    : ""}
                  {isNotificationsEmpty() ? (
                    <MenuItem>Keine Benarichtigung</MenuItem>
                  ) : (
                    data.getNotifications
                      .filter((n: any) => {
                        if (n.from.email !== me.email.emailStr) return n;
                      }).sort((a: any, b: any) => {
                        return  a.date - b.date
                      }).reverse()
                      .map((item: any, key: any) => {
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "4px",
                              }}
                            >
                              <MenuItem
                                style={{ fontSize: "13px", width: "100%" }}
                                disableGutters
                                onClick={() =>
                                  window.location.replace(item.url)
                                }
                              >
                                <Avatar src={item.from.profileImg}></Avatar>
                                <Typography variant="body2" padding={1}>
                                  {item.title}
                                </Typography>
                              </MenuItem>
                            </div>

                          </>
                        );
                      })
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
