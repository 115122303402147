import { useContext, useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Switch,
} from "@material-ui/core";
import { MeContext } from "../../../MeContext";
import { useMutation } from "@apollo/react-hooks";
import {
  ADD_TODO_ITEM,
  EDIT_TODO_ITEM,
  DELETE_TODO_ITEM,
} from "../../../graphql/mutations";
import JiraIssuesSelector from "./JiraIssuesSelector";
import moment from "moment";
import TodoPeriod from "./TodoPeriod";

export const AddTodoButton = (props: any) => {
  const [open, setOpen] = useState(false);
  const [inpText, setInpText] = useState(props.todoItem.text);
  const [editMode, setEditMode] = useState(props.editModeState);
  const [deleteTodoItem, {}] = useMutation(DELETE_TODO_ITEM);
  const [isPeriodTodo, setIsPeriodTodo] = useState(false);
  const [editTodoItem, {}] = useMutation(EDIT_TODO_ITEM);
  const [inpError, setInpError] = useState(false);
  const [todaysDate, setTodaysDate] = useState(moment().format("YYYY-MM-DD"));
  const [dateGoal, setDateGoal] = useState(moment().format("YYYY-MM-DD"));
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const tokens: any = localStorage.getItem("jira_auth");
  const me: any = useContext(MeContext);

  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setIsPeriodTodo(false);
    setInpText("");
    setInpError(false);
    props.resetEditModeState();
  };
  const handleClickOpen = () => setOpen(true);
  const [addTodoItem, {}]: any = useMutation(ADD_TODO_ITEM);
  const handleAddTodoItem = async () => {
    const nowDate = moment().format("YYYY-MM-DD");
    if (inpText === "") setInpError(true);
    else if (todaysDate < nowDate) alert("Ungültiges Datum");
    if (isPeriodTodo && inpError === false) {
      const addPeriodTodo = await addTodoItem({
        variables: {
          _id: props.todo._id,
          text: inpText,
          checked: false,
          withPeriod: true,
          periodRange: todaysDate + "/" + dateGoal,
        },
      });
      setInpText("");
      setOpen(false);
      setIsPeriodTodo(false);
    } else {
      if (inpText === "") setInpError(true);
      else {
         await addTodoItem({
          variables: {
            _id: props.todo._id,
            text: inpText,
            checked: false,
            withPeriod: false,
            periodRange: null,
          },
        });

        setInpText("");
        setOpen(false);
        setIsPeriodTodo(false);
      }
    }
  };
  const handleEditTodoItem = async () => {
    if (inpText === "") setInpError(true);

    const res = await editTodoItem({
      variables: {
        _id: props.todoItem._id,
        text: inpText,
        checked: props.todoItem.checked,
      },
    });
    setOpen(false);
    setIsPeriodTodo(false);
    setEditMode(false);
    setInpText("");
    setInpError(false);
    props.resetEditModeState();
  };
  const handleDeleteTodoItem = async () => {
    const id: any = props.todoItem._id;
    await deleteTodoItem({
      variables: { _id: props.todoItem._id },
      update(cache: any) {
        const normalizedId = cache.identify({ id, __typename: "TodoItem" });
        cache.evict({ _id: normalizedId });
        cache.gc();
      },
    });

    setInpText("");
    handleClose();
  };
  const JiraIssueSelector = (value: any) => {
    setInpText(value.label);
  };
  const handleDateGoal = (date: any) => {
    setDateGoal(date);
  };
  const handleFromDate = (date: any) => {
    setFromDate(date);
  };
  const isDisabled: Function = (): object => {
    const todoDate: any = localStorage.getItem("todo_date");
    if (todoDate < todaysDate)
      return { booleanValue: true, stringValue: "none" };
    if (props.todo.username === me.email.emailStr)
      return { booleanValue: false, stringValue: "" };
    return { booleanValue: true, stringValue: "none" };
  };

  useEffect(() => {
    if (props.editModeState) {
      setEditMode(true);
      setOpen(true);
      setInpText(props.todoItem.text);
    } else {
      setInpText("");
      setEditMode(false);
    }
  }, [props.editModeState]);

  useEffect(() => {}, [editTodoItem, addTodoItem]);

  useEffect(() => {
    const editStatus = props.editModeStatus;
    if (open) {
      const onEnter = (e: any) => {
        if (e.keyCode === 13) {
          if (editMode) handleEditTodoItem();
          else handleAddTodoItem();
        }
      };
      document.addEventListener("keypress", onEnter);
      return () => document.removeEventListener("keypress", onEnter);
    }
  });
  return (
    <div
      style={{
        display: isDisabled().stringValue,
      }}
    >
      <IconButton
        className="fa fa-plus-circle"
        disabled={isDisabled().booleanValue}
        style={{
          display: me.email.emailStr !== props.todo.username ? "none" : "",
          color: "#F28B00",
          fontSize: "25px",
        }}
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h4 style={{ fontFamily: "'Robot', sans-serif", color: "#54595F" }}>
            Aufgabe {editMode ? "akutlisieren" : "Anlegen"}
          </h4>
        </DialogTitle>

        <DialogContent
          style={{ height: "auto", overflow: "clip", minWidth: "550px" }}
        >
          <JiraIssuesSelector
            onChangeHandle={JiraIssueSelector}
            tokens={tokens}
            jiraFilter={me.jira.filter}
          />
          <br />
          <TextField
            fullWidth={true}
            id="outlined-multiline-static"
            multiline
            variant="outlined"
            rows={4}
            value={inpText}
            onChange={(e) => setInpText(e.target.value)}
            placeholder={
              inpError ? "Text Field darf nicht leer sein" : "Text eingeben"
            }
            error={inpError}
            autoFocus
          />
          <Typography
            style={{ float: "left", display: editMode ? "none" : "contents" }}
          >
            {" "}
            Zeitraum
            <Switch
              value={isPeriodTodo}
              color="primary"
              onChange={(e: any) => setIsPeriodTodo(!isPeriodTodo)}
            />
          </Typography>
          <TodoPeriod
            todaysDate={todaysDate}
            dateGoal={dateGoal}
            fromDate={fromDate}
            handleDateGoal={handleDateGoal}
            handleFromDate={handleFromDate}
            isPeriodTodo={isPeriodTodo}
          />
        </DialogContent>
        <DialogActions>
        <Button
            variant="text"
            onClick={handleClose}
            color="default"
            style={{ textTransform: "none", color: "rgb(33	118	217)" }}
          >
            Abbrechen
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={handleDeleteTodoItem}
            style={{ display: editMode ? "" : "none", textTransform: "none" }}
          >
            Löschen
          </Button>
          <Button
            variant="text"
            onClick={editMode ? handleEditTodoItem : handleAddTodoItem}
            style={{ textTransform: "none", color: "rgb(33	118	217)"}}
          >
            {editMode ? "Aktualisieren" : "Hinzufügen"}
          </Button>


        </DialogActions>
      </Dialog>
    </div>
  );
};
