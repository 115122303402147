import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { CONFIRM_PASSWORD } from "../../graphql/mutations";
import { useParams } from "react-router-dom";
import logo from "./../../img/logo.png";
import { Alert } from "@material-ui/lab";

export default function ConfirmPassword() {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [confirmPassword] = useMutation(CONFIRM_PASSWORD);
  const [errAlert, setErrAlert] = useState({
    showAlert: false,
    message: "",
  });

  const params: any = useParams();

  const confirmHandle = async () => {
    if (password !== rePassword)
      return alert("Die Passwörter sind nicht identisch");
    else {
      try {
        await confirmPassword({
          variables: { token: params.token, newPassword: password },
        });
        alert("Das Passwort wurde erfolgreich geändert");
        window.location.replace("/");
      } catch (error: any) {
        setErrAlert({ showAlert: true, message: error.message });
      }
    }
  };

  return (
    <div>
          {errAlert.showAlert ? (
        <Alert severity="error"  style={{ position: "fixed" }}>
          {errAlert.message}
        </Alert>
      ) : (
        ""
      )}
      <header>
        <img src={logo} alt="logo" width="150" height="80" />
      </header>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          variant="filled"
          value={password}
          placeholder="neues Password eingeben"
          onChange={(e) => setPassword(e.target.value)}
          style={{ padding: "10px" }}
        />
        <TextField
          variant="filled"
          value={rePassword}
          placeholder="Password wiederholen"
          onChange={(e) => setRePassword(e.target.value)}
          style={{ padding: "10px" }}
        />
        <Button
          onClick={confirmHandle}
          color="primary"
          style={{ display: "block" }}
        >
          zurücksetzen
        </Button>
      </div>
    </div>
  );
}
