import "./../../../styles/events.css";
import { makeStyles} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      height: "25vh",
      position: "relative",
      borderRadius: "4px",
      backgroundRepeat: "no-repeat",
      border: "0.000001em solid #d7dbe0",
      backgroundSize: "cover",
      "@media (max-width: 780px)": {
        width: "100%",
      },
    },
    overflow:{
        width: "100%",
        height: "10vh",
        position: "absolute",
        backgroundColor: "rgb(78, 79, 82, 0.3)",
        textShadow: "1px 1px black",
        display: "flex",
        justifyContent: "left",
        cursor: "pointer",
        bottom: "0",
        "&:hover": {
            backgroundColor: "rgb(78, 79, 82, 0.8)",
            transition: "0.4s"
        }
    },
    eventTitle:{
        color: "#FFFF", 
        fontSize: "18px",
        cursor: "pointer",
        padding: "10px",
        textDecoration: "none",
        fontFamily: "Sora"
    }
  }));  
export default function EventCategory(props : any ){
    const classes = useStyles();
    
    return (
        <div className={classes.container} style={{backgroundImage: `url(${props.eventCategory.img})`}}>
            <div className={classes.overflow} onClick={() => window.location.replace(`/events/${props.eventCategory.title}`)}>
            <a href={`/events/${props.eventCategory.title}`}  className={classes.eventTitle}>  <p className={classes.eventTitle}>{props.eventCategory.title}</p></a>
            </div>
        </div>
    )
}