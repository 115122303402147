import React, { useContext, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { EVENTS } from "../../../graphql/queries";
import EventCard from "./EventCard";
import {
  Grid,
  makeStyles,
  IconButton,
  createStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";
import AddEventDialog from "./AddEventDialog";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { MeContext } from "../../../MeContext";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { DELETE_EVENT } from "../../../graphql/mutations";
import { client } from "../../../graphql/graphqlConnect";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Logo from "./../../../img/logo.png";
import LoadingScreen from "../../styleComponents/LoadingScreen";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
    },
    container: {
      padding: "10px",
      display: "flex",
      alignItems: "center",
    },
    editIcon: {
      color: "grey",
      marginLeft: "10px",
      transition: "0.5s",
      cursor: "pointer",
      "&:hover": {
        padding: "5px",
        backgroundColor: "silver",
        borderRadius: "25px",
        transition: "0.5s",
      },
    },
    deleteIcons: {
      transition: "0.5s",
    },
  })
);
export default function Events() {
  const { eventCategory }: any = useParams();
  const classes = useStyles();
  const me: any = useContext(MeContext);
  const { loading, error, data } = useQuery(EVENTS, {
    variables: { eventCategoryName: eventCategory },
  });
  const [myEmail, setMyEmail] = useState();
  const [deleteEvent] = useMutation(DELETE_EVENT, {
    onError(e: any) {
      console.log(e);
    },
  });
  const [editMode, setEditMode] = useState(false);

  const showEditIcon = (data: any) => {
    let eventFound = false;
    data.events.forEach((event: any) => {
      if (event.creator.email === myEmail) eventFound = true;
    });
    return eventFound;
  };
  const handleDeleteEvent = async (id: any) => {
    confirmAlert({
      message: "Möchten Sie die Veranstaltung wirklich löschen? ",
      buttons: [
        {
          label: "ja",
          onClick: async () => {
            await deleteEvent({ variables: { _id: id } });
            window.location.reload();
          },
        },
        {
          label: "nein",
          onClick: () => {},
        },
      ],
    });
  };

  const refetchQuery = async () => {
    await client.refetchQueries({
      include: [EVENTS],
    });
  };

  useEffect(() => {
    const email: any = localStorage.getItem("myEmail");
    setMyEmail(JSON.parse(email));
  }, []);
  if (loading) return <LoadingScreen />;
  if (error) return <p>Fehler aufgetreten</p>;

  return (
    <>
      {" "}
      {me ? (
        <div>
          <header>
            <ArrowBackIcon
              onClick={() => window.location.replace("/")}
              style={{
                position: "absolute",
                left: "40",
                color: "white",
                cursor: "pointer",
              }}
            />
            <img src={Logo} style={{ width: "145px", height: "75px" }} />
          </header>
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
              width: "100%",
            }}
          >
            <AddEventDialog refetchFunction={refetchQuery} />
            <IconButton
              style={{ display: showEditIcon(data) ? "" : "none" }}
              onClick={() => setEditMode(!editMode)}
            >
              <EditIcon />
            </IconButton>
            <div
              style={{
                width: "5%",
                float: "right",
                position: "absolute",
                right: "0",
                cursor: "pointer",
              }}
            ></div>
          </Toolbar>
          <Grid container className={classes.container}>
            {data.events.length ? (
              data.events.map((event: any, key: any) => {
                return (
                  <Grid item xs={12} sm={6} xl={3} lg={3} key={key}>
                    <div className={classes.deleteIcons}>
                      <DeleteIcon
                        style={{
                          marginLeft: "30px",
                          cursor: "pointer",
                          display:
                            editMode &&
                            me.email.emailStr === event.creator.email
                              ? ""
                              : "none",
                        }}
                        onClick={() => handleDeleteEvent(event._id)}
                      />
                    </div>
                    <EventCard event={event} />
                  </Grid>
                );
              })
            ) : (
              <Typography variant="body1" sx={{ mt: 6, mb: 1, width: "100%" }} align="center">
                {" "}
                keine Events vorhanden 
              </Typography>
            )}
          </Grid>
        </div>
      ) : (
        <p> bitte einloggen</p>
      )}
    </>
  );
}
