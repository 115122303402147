import React, { useState, useEffect } from "react";
import {
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ViewDayIcon from '@material-ui/icons/ViewDay';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: 5,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function ViewSettings() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef: any = React.useRef(null);
  const [weekOverview, setWeekOverview] = useState(false);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleDailyView = () => {
    localStorage.setItem("week_overview", JSON.stringify(false));
    window.location.reload();
  }
  const handleWeeklyView = () => {
    localStorage.setItem("week_overview", JSON.stringify(true));
    window.location.reload();
  }

  useEffect(() => {
    const getCheckBoxValue: any = localStorage.getItem("week_overview");
    const parseValue = JSON.parse(getCheckBoxValue);
    if (parseValue !== null || "") setWeekOverview(parseValue);
  }, []);
  return (
    <div className={classes.root}>
      <Tooltip title="Tag">
      <IconButton onClick={handleDailyView} style={{color: weekOverview ? "silver": ""}}>
      <ViewDayIcon />
      </IconButton>
      </Tooltip>
      <Tooltip title="Woche">
      <IconButton onClick={handleWeeklyView} style={{color: weekOverview ? "": "silver"}}>
      <ViewWeekIcon />
      </IconButton>
      </Tooltip>
    </div>
  );
}
