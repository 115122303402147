import { useQuery } from "@apollo/react-hooks";
import { useContext, useState, useEffect } from "react";
import { TODAYS_TODO } from "../../../graphql/queries";
import { Grid, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { MeContext } from "../../../MeContext";
import Todo from "./Todo";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    backgroundColor: "#fbfbfd",
  },
}));

export default function TodoGrid(props: any) {
  const [departments, setDepartments] = useState("");
  const classes = useStyles();
  const me: any = useContext(MeContext);
  useEffect(() => {
    const lclDepartments: any = localStorage.getItem("department_selects");
    const parsedLclDepartments = JSON.parse(lclDepartments);
    setDepartments(parsedLclDepartments);
  }, [localStorage.getItem("department_selects")]);

  const { data, loading, error } = useQuery(TODAYS_TODO, {
    variables: { date: props.date },
  });
  if (loading)
    return (
      <div style={{display: "flex", gap: "10px" }}>
        {" "}
        <div>
        <Skeleton variant="circle" width={50} height={50} />
        <Skeleton variant="rect" width={400} height={300} />
        </div>
        <div>
        <Skeleton variant="circle" width={50} height={50} />
        <Skeleton variant="rect" width={400} height={300} />
        </div>
        <div>
        <Skeleton variant="circle" width={50} height={50} />
        <Skeleton variant="rect" width={400} height={300} />
        </div>
      </div>
    );
  if (error) return <p>error...</p>;
  return (

    <Grid container spacing={1} className={classes.container}>
      {data.todaysTodo
        .filter((todo: any) => {

          if (departments.includes("Alle Abteilungen")) return departments.length > 0;

          return departments.includes(todo.department);
        })
        .sort((a: any, b: any) => {
          if(me._id === a._id) return -1
          if(a.name < b.name) return 0
          return 1
        })
        .map((todo: any, key: any) => {
          return (
            <Grid item xs={12} sm={6} xl={3} lg={4} key={key}>
              <Todo todo={todo} todos={data.todaysTodo} />

            </Grid>
          );
        })}
    </Grid>

  );
}
