import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_DEPARTMENTS, USERS } from "../../graphql/queries";
import { confirmAlert } from "react-confirm-alert";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  TableBody,
  Switch,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { DELETE_USERS, UPDATE_USER } from "../../graphql/mutations";
import { client } from "../../graphql/graphqlConnect";
import Select from "react-select";
import LoadingScreen from "../styleComponents/LoadingScreen";
import { MeContext } from "../../MeContext";
interface CheckDeleteUser {
  _id: string;
  checked: boolean;
}
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    width: "130px"
  })
}
const getDepartments = async () => {
  try {
    const req = await client.query({ query: GET_DEPARTMENTS });
    const res = await req;
    return res;
  } catch (e: any) {
    return null;
  }
};
export const UserManagement = () => {
  const me = useContext(MeContext);
  const [departments, setDepartments] = useState([]);
  const [deleteUserCheckbox, setDeleteUserCheckbox] = useState<
    CheckDeleteUser[]
  >([]);
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUsers] = useMutation(DELETE_USERS);

  const handleChange: any = (_id: any) => () => {
    setDeleteUserCheckbox((prev: any) => {
      if (prev.includes(_id)) {
        return prev.filter((x: any) => x !== _id);
      } else {
        return [...prev, _id];
      }
    });
  };
  const handleDeleteUsers = async () => {
    confirmAlert({

      message: "Möchten Sie die Benutzer wirklich löschen? ",
      buttons: [
        {
          label: "ja",
          onClick: async () => {
            await deleteUsers({ variables: { ids: deleteUserCheckbox } });
            refetch()
          },
        },
        {
          label: "nein",
          onClick: () => { },
        },
      ],
    });
  };
  const handleUpdateUser = async (user: any, admin: boolean) => {
    if (admin)
      await updateUser({
        variables: {
          _id: user._id,
          admin: !user.admin,
          approved: user.approved,
          department: user.department,
        },
      });
    else
      await updateUser({
        variables: {
          _id: user._id,
          admin: user.admin,
          approved: !user.approved,
          department: user.department,
        },
      });
  };
  const handleUpdateUserDepartment = async (user: any, value: any) => {
       await updateUser({
        variables: {
        _id: user._id,
        admin: user.admin,
        approved: user.approved,
        department: value.value,
      }
    })
  }
  const selectOptions = (): { value: string; label: string }[] => {
    let options: any = [];
    let department: any;
    for (department of departments) {
      options.push({ value: department.name, label: department.name });
    }
    return options;
  };

  const { loading, error, data, refetch } = useQuery(USERS);
  
  useEffect(() => {
      getDepartments()
      .then((data: any) => {
        setDepartments(data.data.departments);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  if (loading) return <LoadingScreen />;
  if (error) return <p>error</p>;

  return (
    <div style={{ marginTop: "40px" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Auswählen</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>E-Mail</TableCell>
            <TableCell>Abteilung</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Genehmigt</TableCell>
            <TableCell>
              <IconButton
                disabled={deleteUserCheckbox.length === 0 ? true : false}
                onClick={() => handleDeleteUsers()}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.users.map((user: any, key: any) => {
            return (
              <TableRow key={key}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={deleteUserCheckbox.includes(user._id)}
                    onChange={handleChange(user._id)}
                  />
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email.emailStr}</TableCell>
                <TableCell>
                  <Select
                    styles={customStyles}
                    options={selectOptions()}
                    defaultValue={{
                      value: user.department,
                      label: user.department,
                    }}
                    onChange={(e: any) => handleUpdateUserDepartment(user, e)}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.admin}
                    onChange={() => handleUpdateUser(user, true)}
                    disabled={me.email.emailStr === user.email.emailStr}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.approved}
                    onChange={() => handleUpdateUser(user, false)}
                    disabled={me.email.emailStr === user.email.emailStr}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
