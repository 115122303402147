import React from "react";
import BounceLoader from "react-spinners/BounceLoader";
export default function LoadingScreen() {
  return (
    <div>
      <header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ position: "absolute", top: "45%", bottom: "45%" }}>
          <BounceLoader size={50} color="#1277B5" />
        </div>
      </div>
    </div>
  );
}
