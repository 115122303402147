import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import TodoGrid from "./todo/TodoGrid";
import moment from "moment";
import ViewSettings from "./todoWeekoverview/ViewSettings";
import WeekOverview from "./todoWeekoverview/WeekOverview";

export default function Main(props: any) {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [weekOverview, setWeekOverview] = useState(false);
  const dateHandle: any = (newDate: any) => {
    setDate(newDate);
    localStorage.setItem("todo_date", newDate);
  };
  useEffect(() => {
    const getWeekView: any = localStorage.getItem("week_overview");
    const weekView = JSON.parse(getWeekView);
    setWeekOverview(weekView);
  });
  useEffect(() => {
    const lclStorageDate: any = localStorage.getItem("todo_date");
    if (lclStorageDate !== null || "") setDate(lclStorageDate);
  }, []);

  return (
    <div>
      {weekOverview ? (
        <>
          {" "}
          <ViewSettings />
          <WeekOverview />
        </>
      ) : (
        <>
          <DatePicker dateHandle={dateHandle} date={date} />
          <TodoGrid date={date} departments={props.departments} />
        </>
      )}
    </div>
  );
}
