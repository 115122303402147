import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { EDIT_TODO_ITEM } from "../../../graphql/mutations";
import moment from "moment";
import { AddTodoButton } from "../todo/AddTodoButton";
import { Container } from "@mui/material";
import { withStyles } from '@material-ui/core/styles';

const todaysDate: any  = moment(new Date()).format("YYYY-MM-DD")
const useStyles = makeStyles((theme) => ({
  todoItem: {
    width: "100%",
    minWidth: "300px",
    height: "160px",
    border: "0.01em solid grey",
    borderRadius: "3px",
    padding: "10px",
    position: "relative",
  },
  todoCheckBox: {
    position: "absolute",
    bottom: "0",
    left: "0",
  },
  dateLeftRightButton: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "silver",
      borderRadius: "40px",
      transition: "0.6s",
    },
  },
}));
const checkBoxStyles = (theme: any) => ({
  root: {
    '&$checked': {
      color: '#F38B00',
    },
  },
  checked: {},
 })

export default function WeekTodoItem(props: any) {
  const classes = useStyles();
  const [editTextState, setEditTextState] = useState(false);
  const [tempTodoItem, setTempTodoItem] = useState({});
  const [checkBoxValue, setCheckBoxValue] = useState(props.todoItem.checked);
  const [editTodoItem] = useMutation(EDIT_TODO_ITEM);
  const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
  const resetState = () => {
    setEditTextState(false);
  };
  const handleEditIcon = (todoItem: any) => {
    setTempTodoItem(todoItem);
    setEditTextState(!editTextState);
  };
  const handleCheckBox = async () => {
    setCheckBoxValue(!checkBoxValue);
  const res = await editTodoItem({
    
    variables: { _id: props.todoItem._id, text: props.todoItem.text, checked: !checkBoxValue },
  });

};
  return (
    <Container style={{backgroundColor: ""}}>
        <div style={{display: "none"}}>
      <AddTodoButton
        todo={props.todo}
        editModeState={editTextState}
        resetEditModeState={resetState}
        todoItem={tempTodoItem}
      />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "8px",
        }}
      >
        <div className={classes.todoItem}>
          <div style={{height: "90%", width: "100%", overflowY: props.todoItem.text.length > 140 ? "scroll" : "hidden"}}>
          <p >{props.todoItem.text}</p>
          </div>
          <div style={{height: "14%"}}>
          <i
            className="fas fa-pen"
            style={{
              right: 4,
              display: props.todo.date < todaysDate ? "none" : ""
            }}
            id="edit-todo-icon"
            onClick={(e) => handleEditIcon(props.todoItem)}
          ></i>
          
          <CustomCheckbox
            defaultChecked={props.todoItem.checked}
            className={classes.todoCheckBox}
            checked={checkBoxValue}
            disabled={ props.todo.date < todaysDate ? true : false}
            onChange={handleCheckBox}
          />
          </div>

        </div>
      </div>
    </Container>
  );
}
