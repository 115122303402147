import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MeContext } from "../../MeContext";
import "./../../styles/my-profile.css";
import "../../styles/header.css";
import {
  Avatar,
  Typography,
  Button,
  TextField,
  makeStyles,
  Divider,
  Table,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  InputAdornment,
  Input,
  Collapse,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useMutation } from "@apollo/client";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import { confirmAlert } from "react-confirm-alert";
import JiraLogo from "./../../img/Jira.png";
import TaziLogo from "./../../img/logo.png"
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  UPADATE_JIRA_TOKEN,
  UPDATE_JIRA_FILTER,
  CHANGE_PROFILE_IMG,
} from "../../graphql/mutations";
import { Alert } from "@material-ui/lab";
import FavouriteFilters from "../body/FavouriteFilters";
import NewProfileStepper from "./NewProfileStepper";

const useStyles = makeStyles({
  avatar: {
    width: "135px",
    height: "135px",
    margin: "10px",
    zIndex: 0,
    "@media (min-width: 780px)": { minWidth: "60px", minHeight: "60px" },
  },
  nameTitle: {
    color: "#1281C8",
    fontSize: "26px",
    padding: "10px",
  },
  leftTable: {
    fontWeight: "bold",
    textAlign: "start",
  },
  rightTable: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginLeft: "15%",
  },
  emailDisplay: {
    color: "grey",
    fontSize: "15px",
  },
  typography: {
    padding: "10px",
  },
  dataTable: {
    fontSize: "40px",
    alignContent: "center",
  },
  filterInp: {
    width: "75%",
  },
});

export const Profile = React.memo(() => {
  const classes = useStyles();
  const location = useLocation();
  const me: any = useContext(MeContext);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [tokenValue, setTokenValue] = useState({
    password: "",
    showPassword: false,
  });
  const [inpJiraFilter, setInpJiraFilter] = useState("");
  const [favFilterValue, setFavFilterValue] = useState("");
  const [updateJiraFilter] = useMutation(UPDATE_JIRA_FILTER);
  const [updateJiraToken] = useMutation(UPADATE_JIRA_TOKEN);
  const [changeProfileImg] = useMutation(CHANGE_PROFILE_IMG);

  const handleUpdateToken = async () => {
    try {
      await updateJiraToken({
        variables: { token: tokenValue.password },
      });
    } catch (e: any) {
      console.log(e.message);
    }
  };
  const handleChangeProfileImg = async () => {
    try {
      confirmAlert({
        message: "Möchten Sie das Profilbid wirklich ändern ? ",
        buttons: [
          {
            label: "ja",
            onClick: async () => {
              try {
                await changeProfileImg();
                window.location.replace("/my-profile");
              } catch (err) {
                alert("Fehler bei der Änderung des Profilbildes");
              }
            },
          },
          {
            label: "nein",
            onClick: () => {},
          },
        ],
      });
    } catch (error: any) {
      console.log(error.message);
      alert("Profilbild konnte nicht übernommen werden");
    }
  };
  const setFilterValue = (e: any) => {
    setFavFilterValue(e.value);
  };
  const setFavFilter = () => {
    setInpJiraFilter(favFilterValue);
    setEditMode(true);
  };
  const handleChange = (prop: any) => (event: any) => {
    setTokenValue({ ...tokenValue, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setTokenValue({ ...tokenValue, showPassword: !tokenValue.showPassword });
  };

  const updateUser = async () => {
    const filter = inpJiraFilter;
    const modifiedString = filter.replace(/\"/g, `'`).trim();
    handleUpdateToken();
    try {
      await updateJiraFilter({
        variables: { filter: modifiedString },
      });
      setOpen(true);
      setTimeout(() => {
        setOpen(false); window.location.reload();}, 1500);
      
      
    } catch (e: any) {
      console.log(e.message);
    }
  };
  useEffect(() => {
    setTokenValue({ ...tokenValue, password: me?.jira.token });
    if (me !== null) setInpJiraFilter(me?.jira.filter);
  }, [me]);
  return (
    <div>
      {me !== null && me.jira.token ? (
        <div>
          <div className="my-profile">
            <header style={{display: location.pathname === "/my-profile" ? "" : "none"}}>
            <ArrowBackIcon
              onClick={() => window.location.replace("/")}
              style={{
                position: "absolute",
                left: "40",
                color: "white",
                cursor: "pointer",
              }}
            />
            <img src={TaziLogo} style={{ width: "145px", height: "75px" }} alt="tazi_logo" />
            </header>

            <div className="my-profile-box">
            <Collapse
              in={open}
              style={{
                position: "fixed",
                width: "50%",
                height: "5vh",
                bottom: "20px",
                top:  "0",
                zIndex: "1"
              }}
            >
              <Alert
                severity="success"
                style={{ backgroundColor: "#61CE70", color: "white" }}
              >
                Benutzer wurde erfolgreich aktualisiert
              </Alert>
            </Collapse>
              <div className="user-info-box">
                <Avatar
                  className={classes.avatar}
                  variant="circle"
                  src={me.profileImg}
                >
                  {me.name[0] + me.name.split(" ")[1][0]}
                </Avatar>
                <div className="user-info-box-right">
                  <Typography className={classes.nameTitle}>
                    {me.name}
                  </Typography>
                  <Typography className={classes.emailDisplay}>
                    {me.email.emailStr}
                  </Typography>
                  <Typography variant="body1">{me.department}</Typography>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", margin: "30px 0 20px 0"}}>
              <Divider style={{ width: "20%", marginTop: "15px"  }} />
              <img src={JiraLogo} width="25" height="25" style={{margin: "0 0 0 10px"}} alt="jira_logo"/>
              <h3 style={{padding: "0 15px 0 15px", fontFamily: "Open Sans"}}>Jira Einstellungen</h3>
              <Divider style={{ width: "22%", marginTop: "15px"  }} />
              </div>
              <Table className={classes.dataTable}>
                <TableBody>
                  <TableRow>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.leftTable}
                    >
                      Jira Token
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.rightTable}
                    >
                      <Input
                        id="standard-adornment-password"
                        autoComplete="off"
                        type={tokenValue.showPassword ? "text" : "password"}
                        value={tokenValue.password}
                        onChange={handleChange("password")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              disabled
                            >
                              {tokenValue.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <IconButton>
                        <FileCopyIcon
                          onClick={() =>
                            navigator.clipboard.writeText(tokenValue.password)
                          }
                        >
                          Copy
                        </FileCopyIcon>
                      </IconButton>
                      <br />
                      <a
                        href="https://id.atlassian.com/manage-profile/security/api-tokens"
                        target="_blank"
                        style={{
                          color: "#1281C8",
                          fontSize: "11px",
                          fontFamily: "Sora",
                        }}
                      >
                        Möchten Sie ein Token generieren?
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.leftTable}
                    >
                      Jira Profilbild
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.rightTable}
                    >
                      <Button
                        onClick={me?.jira.token ? handleChangeProfileImg: () => {}}
                        style={{ textTransform: "none", color: "#1281C8", cursor:  me?.jira.token ? "" : "not-allowed" }}
                        variant="outlined"
                      ><InsertPhotoIcon /> 
                        <Typography style={{ fontSize: "14px" }}>
                           Als Profilbild übernehmen
                        </Typography>
                        
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.leftTable}
                    >
                      Favoriten filters
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.rightTable}
                    >
                      <FavouriteFilters filterValue={setFilterValue} disabled={tokenValue.password=== " " ? false: true} />
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{ textTransform: "none" }}
                        onClick={setFavFilter}
                      >
                        Filter setzen{" "}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.leftTable}
                    >
                      Jira Filter
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.rightTable}
                    >
                      <TextField
                        value={editMode ? inpJiraFilter : me.jira.filter}
                        onChange={(e: any) => setInpJiraFilter(e.target.value)}
                        autoComplete="off"
                        className={classes.filterInp}
                        placeholder="Jira Filter eingeben"
                        id="outlined-multiline-static"
                        onClick={(e) => setEditMode(true)}
                        disabled={me?.jira.token ? false : true}
                        multiline
                        rows={inpJiraFilter.length < 40 ? 1 : 2}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <div>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => window.location.replace("/")}
                  style={{ textTransform: "none" }}
                >
                  Zurück
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  onClick={updateUser}
                  style={{ textTransform: "none", margin: "10px" }}
                >
                  Speichern
                </Button>
              </div>
              <br />
            </div>
          </div>
        </div>
      ) : !me?.jira.token ? <NewProfileStepper />  : (
        <div>
          <p>bitte einloggen</p>
          <Link to="/login">Zur Einloggen Seute</Link>
        </div>
      )}
    </div>
  );
});
