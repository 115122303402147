import { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import moment from "moment";

export default function TodoPeriod(props: any) {
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  useEffect(() => {
    const dateRange = moment(props.todaysDate)
      .add(20, "days")
      .format("YYYY-MM-DD");
    if (props.date > dateRange) setError2(true);
    if(props.dateGoal < props.todaysDate) setError2(true);
    if(props.fromDate < props.todaysDate) setError(true);
    else {
      setError(false);
      setError2(false);
    }
  }, [props.fromDate]);
  return (
    <div>
      <div style={{ display: props.isPeriodTodo ? "flex" : "none", justifyContent:"center", margin: "10px"}}>
        <TextField
          type="date"
          variant="outlined"
          style={{ width: "170px", marginRight: "55px", zIndex: 0 }}
          value={props.fromDate}
          onChange={(e: any) => props.handleFromDate(e.target.value)}
          error={error}
          label={error ? "ungültiges Datum" : "von Datum"}
        />

        <TextField
          type="date"
          variant="outlined"
          style={{ width: "170px", zIndex: 0}}
          value={props.dateGoal}
          onChange={(e) => props.handleDateGoal(e.target.value)}
          error={error2}
          label={error2 ? "Datum bis dd-mm" : "bis Datum"}
        />
      </div>
    </div>
  );
}
