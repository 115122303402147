import React, { useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Grid,
  IconButton,
  Theme,
  createStyles,
  makeStyles,
  Button,
  Toolbar
} from "@material-ui/core";
import { EVENT_CATEGORIES } from "../../../graphql/queries";
import { MeContext } from "../../../MeContext";
import EventCategory from "./EventCategory";
import { DELETE_EVENT_CATEGORY } from "../../../graphql/mutations";
import AddEventCategoryDialog from "./AddEventCategoryDialog";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from "react-confirm-alert";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fbfbfd",
    },
    container: {
      padding: "10px",
    },
  })
);
export default function EventCategories() {
  const me: any = useContext(MeContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteEventCategory] = useMutation(DELETE_EVENT_CATEGORY);
  const { loading, error, data, refetch } = useQuery(EVENT_CATEGORIES);
  const handleCloseDialog = () => {
    setOpen(false);
  }

  const handleDeleteEventCategory = async (id: any) => {
    confirmAlert({

      message: "Möchten Sie die Kategorie wirklich löschen? ",
      buttons: [
        {
          label: "ja",
          onClick: async () => {
            await deleteEventCategory({ variables: { _id: id } })
            refetch()
          },
        },
        {
          label: "nein",
          onClick: () => { },
        },
      ],
    });

  }
  if (loading) return <p>loading...</p>;
  if (error) return <p>Fehler aufgetreten</p>;

  return (
    <>

      {me === null ? (
        <div>bitte einloggen</div>
      ) : (
        <div style={{marginTop: '50px'}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px"}}>
          <Typography variant="h6"  sx={{ mt: 4, mb: 1, ml: 2 }}>Events Kategorien</Typography>
          <Toolbar>
            <Button variant="outlined" onClick={() => setOpen(true)}>create</Button>
            <AddEventCategoryDialog open={open} handleCloseDialog={handleCloseDialog} refetch={refetch} />
            <IconButton style={{ display: me.admin ? '' : 'none' }} onClick={() => setEditMode(!editMode)}>
              <EditIcon></EditIcon>
            </IconButton>
          </Toolbar>
          </div>
          <div className={classes.root}>
            <Grid container spacing={1} className={classes.container}>
              {data.eventCategories.map((item: any, key: any) => {
                return (
                  <Grid item xs={12} sm={6} xl={4} lg={4} key={key}>
                    <DeleteIcon style={{ cursor: "pointer",display: me.admin && editMode ? "" : "none" }} onClick={() => handleDeleteEventCategory(item._id)} />
                    <EventCategory eventCategory={item} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}
