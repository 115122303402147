import { useContext, useState, useEffect } from "react";
import { MeContext } from "../../../MeContext";
import { useParams } from "react-router-dom";
import { GET_EVENT } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import moment from "moment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { AvatarGroup } from "@material-ui/lab";
import { Avatar, Tooltip } from "@mui/material";
import { EditorState, Editor, convertFromRaw } from "draft-js";
import EventIcon from "@material-ui/icons/Event";

const ImgBackground = styled.img`
  width: 100%;
  height: 60vh;
  backdrop-filter: blur(10px); /* apply the blur */
  pointer-events: none; /* make the overlay click-through */
  position: fixed;
  z-index: -1;
`;
const EventMain = styled.main`
  width: 100%;
  height: 80vh;
`;
const EventListing = styled.div`
  min-width: 700px;
  width: 45%;
  height: 80vh;
  background-color: yellow;
  background-color: #ffff;
  margin: 50px auto 0 auto;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  border-radius: 5px;
`;
const EventHeader = styled.header`
  width: 100%;
  height: 40%;
`;
const EventHeader_Img = styled.img`
  width: 60%;
  height: 100%;
  float: left;
`;
const EventHeader_Details = styled.div`
  width: 40%;
  height: 100%;
  float: right;
  position: relative;
  background-color: rgb(242 238 238);
  border-bottom: 0.01rem solid rgb(71, 70, 68, 20%);
`;
const EventHeader_Details_Date = styled.p`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #63625f;
  font-family: Neue Plak, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
`;
const EventHeader_Details_Title = styled.span`
  font-family: Sora;
  font-size: 2vh;
  padding: 25px;
`;
const EventHeader_Details_category = styled.p`
  position: absolute;
  bottom: 1%;
  padding: 20px;
  color: #63625f;
  font-weight: 600;
  font-family: Neue Plak, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
`;
const EventBody_Desription = styled.span`
  font-family: Sora;
  font-size: 13px;
  padding: 10px;
`;
const EventActionBar = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 0.01rem solid rgb(71, 70, 68, 20%);
  position: relative;
  font-family: Sora;
`;
const EventBody = styled.main`
  width: 100;
  height: 50%;
  padding: 20px;
`;
const Button = styled.button`
  width: 32%;
  height: 40px;
  border-radius: 5px;
  background-color: #5e4bc3;
  color: #ffff;
  border: none;
  position: absolute;
  right: 5%;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: silver;
    transition: 0.3s;
  }
  &[disabled] {
    background-color: silver;
    cursor: not-allowed;
  }
`;
const H3 = styled.h3`
  font-family: Sora;
`;
const Event_Info = styled.div`
  width: 36%;
  height: 100%;
  float: right;
  color: #63625f;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  h4 {
    display: inline-block;
    padding-left: 15px;
  }
`;
export default function Event() {
  const me = useContext(MeContext);
  const { eventId }: any = useParams();
  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: { eventId: eventId },
  });
  const showTime = (): boolean => {
    const toDate = moment(data.event.toDate).format("YYYY-MM-DD");
    const fromDate = moment(data.event.fromDate).format("YYYY-MM-DD");
    const fromDateTime = moment(data.event.date).format("HH:mm");
    const toDateTime = moment(data.event.toDate).format("HH:mm");

    if (toDate === fromDate && fromDateTime === toDateTime) return false;
    if (toDate !== fromDate) return false;
    if (moment(data.event.toDate) > moment(new Date(2000, 0, 0))) return true;
    return false;
  };
  const setRichtText = (richtText: string) => {
    const rawContent = richtText;
    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  return (
    <div style={{width: "100%", height: "100vh"}}>
      {me !== null ? (
        <EventMain>
          <header />
          {loading ? (
            <p>loading</p>
          ) : (
            <>
              <ImgBackground src={data.event.img} />
              <EventListing>
                <EventHeader>
                  <EventHeader_Img src={data.event.img} />
                  <EventHeader_Details>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "25%",
                          height: "75px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <EventHeader_Details_Date>{`${moment(
                          data.event.date
                        ).format("MMM")}`}</EventHeader_Details_Date>
                        <EventHeader_Details_Date>{`${moment(
                          data.event.date
                        ).format("M")}`}</EventHeader_Details_Date>
                      </div>

                      <EventHeader_Details_Title>
                        {data.event.title}
                      </EventHeader_Details_Title>
                      <div
                        style={{
                          color: "#63625f",
                          fontFamily: "Sora",
                          fontSize: "11px",
                          padding: "30px",
                        }}
                      >
                        von{" "}
                        <Tooltip title={data.event.creator.email}>
                          <a
                            style={{
                              color: "rgb(54	89	227	)",
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                          >
                            {data.event.creator.name}
                          </a>
                        </Tooltip>
                      </div>
                      <EventHeader_Details_category>
                        {" "}
                        Kategorie: {data.event.category}
                      </EventHeader_Details_category>
                    </div>
                  </EventHeader_Details>
                </EventHeader>

                <EventActionBar>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      float: "left",
                    }}
                  >
                    <p>Teilnehmer: </p>
                    <AvatarGroup max={10} style={{ padding: "5px" }}>
                      <Tooltip title={data.event.creator.email}>
                        <Avatar
                          alt={data.event.creator.name}
                          src={data.event.creator.profileImg}
                        >
                          {data.event.creator.name[0] +
                            data.event.creator.name.split(" ")[1][0]}
                        </Avatar>
                      </Tooltip>
                      {data.event.participants.map((p: any, key: any) => {
                        console.log(p._id, me._id);
                        return (
                          <Tooltip title={p.name}>
                            <Avatar alt={p.name} src={p.profileImg} key={key}>
                              {p.name[0] + p.name.split(" ")[1][0]}
                            </Avatar>
                          </Tooltip>
                        );
                      })}
                    </AvatarGroup>
                  </div>
                  <Button
                    onClick={() => alert("test")}
                    disabled={me.email.emailStr === data.event.creator.email}
                  >
                    {data.event.participants.some(
                      (p: any) => p.username === me.email.emailStr
                    )
                      ? "Verlassen"
                      : "Beitreten"}
                  </Button>
                </EventActionBar>
                <EventBody>
                  <section style={{ float: "left" }}>
                    <H3>Beschreibung </H3>
                    <EventBody_Desription>
                      <Editor
                        editorState={setRichtText(data.event.text)}
                        onChange={() => {}}
                        readOnly={true}
                      />
                    </EventBody_Desription>
                  </section>
                  <Event_Info>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <EventIcon fontSize="small" />
                      <h4>Datum und Uhrzeit</h4>
                    </div>
                    <div>
                      {moment(data.event.date).format("DD-MM-YYYY") ===
                      moment(data.event.toDate).format("DD-MM-YYYY")
                        ? moment(data.event.date).format("dd. M MMMM YYYY")
                        : `${moment(data.event.date).format("dd. M MMMM YYYY")} - ${moment(data.event.toDate).format("dd. M MMMM YYYY")}`}
                    </div>
                    <div>
                      {moment(data.event.date).format("HH:mm")}
                    </div>
                  </Event_Info>
                </EventBody>
              </EventListing>
            </>
          )}
        </EventMain>
      ) : (
        <p>not logged in</p>
      )}
    </div>
  );
}
