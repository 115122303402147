import { useQuery } from "@apollo/client";
import Select from "react-select";
import { GET_FAVOURITE_FILTERS } from "../../graphql/queries";
import "./../../styles/my-profile.css";
import { makeStyles, Theme, createStyles } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      fontSize: "18px",
      padding: "15px",
      cursor: "pointer",
    },
  })
);

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
  }),
};

export default function FavouriteFilters(props: any) {
  const classes = useStyles();

  const onChangeHandle = (e: any) => {
    console.log(e);
    props.filterValue(e);
  };

  const setOptions = (data: any) => {
    const filters: Array<any> = [];

    for (let filter of data.getFavouriteFilter) {
      filters.push({ value: filter.jql, label: filter.name, url: filter.url });
    }

    return filters;
  };

  const Option = (props: any) => {
    const { data, innerProps } = props;
    const option: any = `<p>${data.label}<a href=${data.url} target="_blank">vorschau</a></p>`;

    return (
      <p
        className={classes.menuItem}
        dangerouslySetInnerHTML={{ __html: option.trim() }}
        {...innerProps}
      />
    );
  };

  const { loading, error, data } = useQuery(GET_FAVOURITE_FILTERS);

  if (loading) return <Select placeholder="loading..." isLoading />;
  if (error) return <Select placeholder="Jira ist nicht verbunden" />;


  return (
    <div style={{ minWidth: "55%", padding: "10px" }}>
      <Select
        components={{ Option }}
        options={setOptions(data)}
        styles={customStyles}
        placeholder="Filter auswählen"
        isSearchable
        noOptionsMessage={() => "keine Filter vohanden"}
        onChange={onChangeHandle}
      />
    </div>
  );
}
