import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { RESET_PASSWORD } from "../../graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import logo from "./../../img/logo.png";

export default function ResetPassword() {
  const [inpEmail, setInpEmail] = useState("");
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const resetPasswordHandle = () => {

    try {
      resetPassword({ variables: { email: inpEmail.toLowerCase() } });
      alert("der Link wurde an Ihre Email gesendet");
    } catch (err: any) {
      console.log(err)
      alert('Fehler')
    }

  }
  return (
    <div>
      <header>
        <a href="/">
        <img src={logo} width="120" height="60" alt="logo" />
        </a>
      </header>
      <br />
      <div style={{
        display: "flex",
        justifyContent: "center",
        margin: "40px"
      }}>
      <TextField
        variant="outlined"
        value={inpEmail}
        style={{width: "300px"}}
        onChange={(e) => setInpEmail(e.target.value)}
        placeholder="E-mail eingeben"
      />
      <Button
        variant="contained"
        style={{color: "black", margin: "5px"}}
        onClick={resetPasswordHandle}
      >
        Password zurücksetzen
      </Button>
      </div>
    </div>
  );
}
