import { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableContainer,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useQuery } from "@apollo/client";
import { GET_WEEKLY_TODOS } from "../../../graphql/queries";
import moment from "moment";
import WeekTodoItem from "./WeekTodoItem";
import { AddTodoButton } from "../todo/AddTodoButton";
import "moment/locale/de";

moment.locale("de");
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: "auto",
    width: "100%",
    borderBottom: "none",
    border: "1px solid silver",
    borderRadius: "5px",
  },
  tableHeader: {
    width: "100%",
    backgroundColor: "grey",
  },
  tableBody: {
    width: "100%",
    height: "100%",
  },
  daysCells: {
    width: "20%",
    textAlign: "center",
    color: "white",
    position: "relative",
    fontSize: "15px",
    borderRight: "1px solid silver",
  },
  todosCells: {
    width: "20%",
    textAlign: "center",

    color: "white",
    position: "relative",
    fontSize: "15px",
    borderRight: "1px solid silver",
  },
  todosItemCells: {
    color: "black",
    borderBottom: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  daysRow: {
    width: "100%",
    height: "6vh",
  },

  todayButton: {
    marginLeft: "20px",
    color: "#FFFF",
    padding: "4px",
    cursor: "pointer",
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Sans-serif ",
    backgroundColor: "#1281C8",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#EEF1F3",
      color: "#1281C9",
      border: "1px solid #1281C8",
      transition: "0.5s",
    },
  },
  dateLeftRightButton: {
    cursor: "pointer",
    marginLeft: "20px",
    fontSize: "25px",
    "&:hover": {
      backgroundColor: "silver",
      borderRadius: "40px",
      transition: "0.6s",
    },
  },
}));

function getWeekRange(date: any) {
  const monday: any = moment(date).day(1);
  const friday: any = moment(date).day(5);
  const from = moment(date).day(1).format("YYYY-MM-DD");
  const to = moment(date).day(5).format("YYYY-MM-DD");
  return { start: monday, end: friday, from: from, to: to };
}
function setWeekDays(date: any): Array<Object> {
  date = new Date(date);
  let start: any = getWeekRange(date).start;
  let end: any = getWeekRange(date).end;
  let days: any = [];
  for (start; start <= end; start.add(1, "day")) {
    if (
      moment(start).format("YYYY-MM-DD") === moment(end).format("YYYY-MM-DD")
    ) {
      days.push(start.date());
      break;
    } else {
      days.push(start.date());
    }
  }

  return days;
}
export default function WeekOverview() {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState<any>(moment());
  const weekDays: Array<string> = ["Mo.", "Di.", "Mi.", "Do.", "Fr."];
  const [days, setDays] = useState([]);
  const [editTextState, setEditTextState] = useState(false);
  const [cells, setCells] = useState([]);

  const { loading, error, data } = useQuery(GET_WEEKLY_TODOS, {
    variables: {
      from: getWeekRange(currentDate).from,
      to: getWeekRange(currentDate).to,
    },
  });

  const handleNexWeek = () => {
    setCells([]);
    const nextWeek = moment(currentDate).day(1).add(7, "days");
    setCurrentDate(moment(nextWeek));
  };
  const handlePreviousWeek = () => {
    setCells([]);
    const nextWeek = moment(currentDate).day(1).add(-7, "days");
    setCurrentDate(moment(nextWeek));
    setCurrentDate(nextWeek);
  };

  useEffect(() => {
    const resetTodoDate = localStorage.setItem(
      "todo_date",
      moment(new Date()).format("YYYY-MM-DD")
    );
    const test: any = setWeekDays(currentDate);
    setDays(test);
  }, [currentDate]);

  const resetState = () => {
    setEditTextState(false);
  };

  if (loading) return <p>loading...</p>;
  if (error) return <p>error</p>;
  
  return (
    <div style={{ marginTop: "60px"}}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "auto",
          height: "5.5vh",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <DateRangeIcon />
        <Typography
          style={{ fontSize: "14px", padding: "5px", width: "200px" }}
        >
          {getWeekRange(currentDate).start.format("MMM D") +
            " - " +
            getWeekRange(currentDate).end.format("MMM D")}
        </Typography>
        <Typography>
          <KeyboardArrowLeftIcon
            onClick={handlePreviousWeek}
            className={classes.dateLeftRightButton}
          />{" "}
          <KeyboardArrowRightIcon
            onClick={handleNexWeek}
            className={classes.dateLeftRightButton}
          />{" "}
        </Typography>
        <Button
          className={classes.todayButton}
          onClick={(e) => {
            setCurrentDate(moment());
            if (
              currentDate.format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
            ) {
            } else {
              setCells([]);
              setCurrentDate(moment());
            }
          }}
        >
          Heute
        </Button>
      </div>
      <TableContainer component="div" className={classes.tableContainer}>
        <Table >
          <TableHead className={classes.tableHeader} component="thead">
            {weekDays.map((day: any, key: any) => {
              return (
                <TableCell className={classes.daysCells} key={key} >
                  {day} {days[key]}
                </TableCell>
              );
            })}
          </TableHead>
            {data.getWeeklyTodos.map((todo: any, key: any) => {
              return (
                <TableCell className={classes.todosCells}  padding="none" >
                    {" "}
                    <AddTodoButton
                      todo={todo}
                      editModeState={editTextState}
                      resetEditModeState={resetState}
                      todoItem={{}}
                    />
                  {todo.todos.map((todoItem: any, key: any) => {
                    return (
                      <TableCell className={classes.todosItemCells} padding="none">
                        <WeekTodoItem
                          todoItem={todoItem}
                          todo={todo}
                          key={key}
                        />
                      </TableCell>
                    );
                  })}
           
                </TableCell>
              );
            })}
        </Table>
      </TableContainer>
    </div>
  );
}
