import React, {useState} from "react";
import { Checkbox } from "@material-ui/core";
import { EDIT_TODO_ITEM } from "../../../graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import { withStyles } from '@material-ui/core/styles';
import moment from "moment";


const checkBoxStyles = (theme: any) => ({
  root: {
    '&$checked': {
      color: '#F38B00',
    },
  },
  checked: {},
 })



export default function TodoCheckbox(props: any) {
  const [checkboxValue, setCheckBoxValue] = useState(props.todo.checked);
  const [editTodoItem, {}] = useMutation(EDIT_TODO_ITEM);
  const [todaysDate, setTodaysDate] = useState(moment().format("YYYY-MM-DD"));
  const globalDate : any = localStorage.getItem("todo_date");
  const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
  const handleCheckBox = async () => {
      setCheckBoxValue(!checkboxValue);

    const res = await editTodoItem({
      variables: { _id: props.todo._id, text: props.todo.text, checked: !checkboxValue },
    });

  };
  return (
    <CustomCheckbox
      style={{ padding: "0px", display: props.myEmail === props.todoUsername ? "" : "none"}}
      disabled={todaysDate > globalDate ? true : false}
      checked={checkboxValue}
      onChange={handleCheckBox}
    />
  );
}
