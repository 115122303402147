import React, { useState, useContext, useEffect } from "react";
import { Avatar, Button, makeStyles, Tooltip } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import moment from "moment";
import "./../../../styles/event.css";
import { useMutation } from "@apollo/client";
import { JOIN_EVENT, LEAVE_EVENT } from "../../../graphql/mutations";
import { MeContext } from "../../../MeContext";
import EventComments from "./EventCommments";
import { EditorState, Editor, convertFromRaw } from "draft-js";

const useStyles = makeStyles({
  container: {
    width: "350px",
    minHeight: "560px",
    borderRadius: "8px",
    position: "relative",
    backgroundColor: "#E5E9FA",
    marginTop: "10px",
    border: "0.1em solid silver",
  },
  avatar: {
    width: "100%",
    height: "180px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  eventBody: {
    width: "100%",
    height: "385px",
    backgroundColor: "#E5E9FA",
    borderRadius: "15px",
    position: "absolute",
    top: "150px",
  },
  title: {
    fontFamily: "Sora",
    color: "#323450",
    padding: "10px",
    fontWeight: 1000,
    "&:hover":  {
      textDecoration: "underline",
      cursor: "pointer",
      transitions: "0.5s"
    }
  },
  date: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Sora",
    fontSize: "15px",
    padding: "10px",
  },
  BodyFooter: {
    width: "100%",
    maxHeight: "140px",
    display: "flex",
    padding: "4px",
    fontFamily: "Sora",
    overflow: "auto",
  },
  joinBtn: {
    width: "250px",
    backgroundColor: "#5E4BC3",
    marginTop: "60px",
    color: "white",
    textTransform: "none",
  },
  eventFooter: {
    width: "100%",
    maxHeight: "35px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "30px",
    textTransform: "none",
  },
});

export default function EventCard(props: any) {
  const classes = useStyles();
  const me: any = useContext(MeContext);
  const [joinEvent]: any = useMutation(JOIN_EVENT);
  const [leaveEvent]: any = useMutation(LEAVE_EVENT);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [myEmail, setMyEmail] = useState("test");
  const handleJoinEvent = async () => {
    await joinEvent({
      variables: { eventId: props.event._id, username: me.email.emailStr },
    });
  };
  const handleLeaveEvent = async () => {
    await leaveEvent({
      variables: { eventId: props.event._id, username: me.email.emailStr },
    });
  };
  const showTime = (): boolean => {
    const toDate = moment(props.event.toDate).format("YYYY-MM-DD");
    const fromDate = moment(props.event.fromDate).format("YYYY-MM-DD");
    const fromDateTime = moment(props.event.date).format("HH:mm");
    const toDateTime = moment(props.event.toDate).format("HH:mm");

    if (toDate === fromDate && fromDateTime === toDateTime) return false;
    if (toDate !== fromDate) return false;
    if (moment(props.event.toDate) > moment(new Date(2000, 0, 0))) return true;
    return false;
  };
  const redirectToEvent = () => {
    const link: string = `${window.location.origin}/events/${props.event.category}/${props.event._id}`;
    window.open(link, "_blank")
  }
  useEffect(() => {
    const email: any = localStorage.getItem("myEmail");
    setMyEmail(JSON.parse(email));
    const rawContent = props.event.text;

    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }, [props.event.text]);
  
  return (
    <div className={classes.container}>
      <div
        className={classes.avatar}
        style={{ backgroundImage: `url(${props.event.img})` }}
      ></div>
      <div className={classes.eventBody}>
        <h3 className={classes.title} onClick={redirectToEvent}>{props.event.title} </h3>
        <div className={classes.date}>
          <EventIcon
            style={{
              backgroundColor: "silver",
              borderRadius: "20px",
              padding: "5px",
              fontSize: "32px",
            }}
          />{" "}
          <p style={{ marginLeft: "20px" }}>
            {moment(props.event.date).format("DD.MM.YYYY")}
          </p>
          {moment(props.event.toDate) > moment(new Date(2000, 0, 0)) &&
          moment(props.event.toDate).format("YYYY-MM-DD") !==
            moment(props.event.fromDate).format("YYYY-MM-DD") ? (
            <p style={{ padding: "5px" }}>{`- ${moment(
              props.event.toDate
            ).format("DD.MM.YYYY")}`}</p>
          ) : moment(props.event.toDate).format("YYYY-MM-DD") ===
            moment(props.event.fromDate).format("YYYY-MM-DD") ? (
            ""
          ) : (
            ""
          )}
        </div>
        <div
          className={classes.date}
          style={{
            display:
              moment(props.event.date).format("HH:mm") === "02:00"
                ? "none"
                : "",
          }}
        >
          <ScheduleIcon
            style={{
              backgroundColor: "silver",
              borderRadius: "20px",
              padding: "5px",
              fontSize: "32px",
            }}
          />
          <p style={{ marginLeft: "20px" }}>
            {moment(props.event.date).format("HH:mm")} Uhr
          </p>
          {showTime() ? (
            <p style={{ padding: "5px" }}>{`bis ${moment(
              props.event.toDate
            ).format("HH:mm")}`}</p>
          ) : (
            ""
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AvatarGroup max={9} style={{ padding: "5px" }}>
            <Tooltip title={props.event.creator.email}>
              <Avatar
                alt={props.event.creator}
                src={props.event.creator.profileImg}
              />
            </Tooltip>
            {props.event.participants.map((participant: any, key: any) => {
              return (
                <Tooltip title={participant.name}>
                  <Avatar alt={participant.name} src={participant.profileImg}>
                    {participant.name[0] + participant.name.split(" ")[1][0]}
                  </Avatar>
                </Tooltip>
              );
            })}
          </AvatarGroup>
          <p
            style={{ color: "#323450", fontFamily: "Sora", marginLeft: "0px" }}
          >
            {props.event.participants.length === 0 ? "" : "sind dabei"}
          </p>
        </div>
        <h4 className={classes.title}>Über : </h4>
        <div className={classes.BodyFooter}>
          <Editor
            editorState={editorState}
            onChange={() => {}}
            readOnly={true}
          />
        </div>
        <div className={classes.eventFooter}>
          {props.event.participants.some(
            (participant: any) => participant.username === myEmail
          ) ? (
            <Button
              variant="contained"
              className={classes.joinBtn}
              onClick={(e: any) => handleLeaveEvent()}
            >
              verlassen
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.joinBtn}
              onClick={(e: any) => handleJoinEvent()}
              disabled={props.event.creator.email === me.email.emailStr}
            >
              Beitreten
            </Button>
          )}
          <EventComments event={props.event} />
        </div>
      </div>
    </div>
  );
}
