import React, { useState, useEffect } from "react";
import { TextField, Button, Select, MenuItem } from "@material-ui/core";
import Logo from "./../../img/logo.png";
import { Link, useHistory } from "react-router-dom";

import { GET_DEPARTMENTS } from "../../graphql/queries";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CHECK_DUPLICATE_EMAIL, CREATE_USER } from "../../graphql/mutations";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from "@material-ui/lab/Alert";

export default function SignUp() {
  const history = useHistory();
  const [inpName, setInpName] = useState("");
  const [inpPassword, setInpPassword] = useState("");
  const [inpEmail, setInpEmail] = useState("");
  const [inpDepartment, setInpDepartment] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [createUser] = useMutation(CREATE_USER);
  const [checkDuplicateEmail] = useMutation(CHECK_DUPLICATE_EMAIL);
  const { loading, error, data } = useQuery(GET_DEPARTMENTS);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [errAlert, setErrAlert] = useState<any>({
    showAlert: false,
    message: '',
    type: ''
  })


  const signUpHandle = async () => {
    setLoadingBtn(true);
    if (!setInpName || !inpEmail || !inpDepartment || !inpPassword || emailErr)
      setErrAlert({showAlert: true, message: 'Bitte alle Felder ausfüllen', type: "error"})
    else {
      try {
           await createUser({
          variables: {
            data: {
              name: inpName,
              email: inpEmail.toLowerCase(),
              password: inpPassword,
              department: inpDepartment,
              admin: false,
            },
          },
        });
        setErrAlert({showAlert: true, message: 'Bestätigung wurde an Ihre Email gesendet', type: "success"})
        setTimeout(() => {
          setErrAlert({showAlert: false, message: '', type: ''})
          history.push("/");
        },3000)

      } catch (error: any) {
        console.log(error)
        setErrAlert({showAlert: true, message: 'Fehler beim registrieren', type: "error"})
      }
    }
    setLoadingBtn(false);
  };
  const errAlertOnClose = () => {
    setErrAlert({
      showAlert: false,
      message: '',
      type: ''
    })
  }
  const checkEmailHandle = async () => {
    const checkEmail = await checkDuplicateEmail({
      variables: { email: inpEmail },
    });
    setEmailErr(checkEmail.data.checkDuplicateEmail);
  };
  useEffect(() => {
    setTimeout(() => {
      setErrAlert({showAlert: false, message: '', type: ''})
    }, 5000)

  },[errAlert.showAlert === true])
  if (loading) return <p>loading...</p>;
  if (error) return <p>error...</p>;

  return (
    <div className="login-main-container">
      {errAlert.showAlert ? <Alert severity={errAlert.type} style={{position: "fixed"}} onClose={errAlertOnClose}>{errAlert.message}</Alert> : ''}
      <img src={Logo} width="215" height="135" alt="logo"></img>{" "}
      <div className="login-box">
        <h1>Registrieren</h1>
        <hr></hr>
        <div className="textBox">
          <i className="fas fa-user"></i>
          <TextField
            autoFocus
            type="text"
            style={{ width: "80%" }}
            placeholder="Vollständiger Name"
            value={inpName}
            onChange={(e) => setInpName(e.target.value)}
          />
        </div>
        <div className="textBox">
          <i className="fas fa-envelope"></i>
          <TextField
            autoFocus
            error={emailErr}
            helperText={emailErr ? "email already used" : ""}
            type="text"
            style={{ width: "80%" }}
            placeholder="Email"
            value={inpEmail}
            onBlur={checkEmailHandle}
            onChange={(e) => setInpEmail(e.target.value)}
          />
        </div>
        <div className="textBox">
          <i className="fas fa-lock"></i>
          <TextField
            type="password"
            placeholder="Passwort"
            style={{ width: "80%" }}
            value={inpPassword}
            onChange={(e) => setInpPassword(e.target.value)}
          />
        </div>
        <div className="textBox">
          <i className="fas fa-building"></i>

          <Select
            style={{ width: "80%", color: "white"}}
            value={inpDepartment}
            displayEmpty
            onChange={(e: any) => setInpDepartment(e.target.value)}
          >
            <MenuItem value="" disabled >
              <span style={{marginLeft: "8px", color: "#A0A8AC"}}>Abteilungen...</span>
            </MenuItem>
            {data.departments.map((department: any) => {
              return <MenuItem value={department.name}>{department.name}</MenuItem>;
            })}
          </Select>
        </div>
        <br />
        <Button
          onClick={() => signUpHandle()}
          value="Sign in"
          className="btn"
          disabled={loadingBtn}
          style={{
            backgroundColor: loadingBtn ? "" : "#f18a01",
            color: "white",
          }}
        >
          {loadingBtn ? <CircularProgress style={{color: '#f18a01'}} />  : "Registrieren"}
          
        </Button>

        <br />
        <Link to="/login" style={{ color: "silver" }}>
          zum einloggen
        </Link>
      </div>
    </div>
  );
}
