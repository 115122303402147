import gql from "graphql-tag";

const CREATE_USER = gql`
  mutation createUser($data: iUser!) {
    createUser(data: $data) {
      _id
    }
  }
`;

const LOGIN = gql`
  mutation login($login: iLogin!) {
    login(login: $login)
  }
`;
const LOGOUT = gql`
  mutation logout {
    logout
  }
`;
const CONFIRM_EMAIL = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token)
  }
`;
const CHECK_DUPLICATE_EMAIL = gql`
  mutation checkDuplicateEmail($email: String!) {
    checkDuplicateEmail(email: $email)
  }
`;
const ANSWER_JOIN_REQUEST = gql`
  mutation answerJoinRequest(
    $_id: String!
    $userId: String!
    $answer: Boolean!
  ) {
    answerJoinRequest(_id: $_id, userId: $userId, answer: $answer)
  }
`;
const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;
const CONFIRM_PASSWORD = gql`
  mutation confirmPassword($token: String!, $newPassword: String!) {
    confirmPassword(token: $token, newPassword: $newPassword)
  }
`;
const ADD_TODO_ITEM = gql`
  mutation addTodoItem(
    $_id: String!
    $text: String!
    $checked: Boolean!
    $withPeriod: Boolean!
    $periodRange: String
  ) {
    addTodoItem(
      _id: $_id
      text: $text
      checked: $checked
      withPeriod: $withPeriod
      periodRange: $periodRange
    ) {
      _id
      username
      todos {
        _id
        text
        checked
      }
    }
  }
`;
const DELETE_TODO_ITEM = gql`
  mutation deleteTodoItem($_id: String!) {
    deleteTodoItem(_id: $_id) {
      _id
      username
      todos {
        _id
        text
        checked
      }
    }
  }
`;
const EDIT_TODO_ITEM = gql`
  mutation editTodoItem($_id: String!, $text: String!, $checked: Boolean!) {
    editTodoItem(_id: $_id, text: $text, checked: $checked) {
      todos {
        _id
        text
        checked
      }
    }
  }
`;
const GET_JIRA_AUTH_CODE = gql`
  mutation getJiraAccessToken($authCode: String!) {
    getJiraAccessToken(authCode: $authCode) {
      accToken
      refreshToken
    }
  }
`;
const REFRESH_JIRA_TOKEN = gql`
  mutation refreshJiraToken($refreshToken: String!) {
    refreshJiraToken(refreshToken: $refreshToken) {
      accToken
      refreshToken
    }
  }
`;
const UPDATE_JIRA_FILTER = gql`
  mutation updateJiraFilter($filter: String!) {
    updateJiraFilter(filter: $filter) {
      _id
      name
      email {
        emailStr
      }
      jira {
        filter
      }
    }
  }
`;
const CREATE_EVENT_CATEGORY = gql`
  mutation createEventCategory($title: String!, $img: String!) {
    createEventCategory(title: $title, img: $img) {
      _id
      title
      img
    }
  }
`;
const CREATE_EVENT = gql`
  mutation createEvent($data: iEvent!) {
    createEvent(data: $data) {
      _id
      creator{
        name 
        email 
        profileImg
      }
      title
      text
      date
      toDate
      img
      category
      participants {
        _id
        username
        name
        profileImg
      }
    }
  }
`;
const JIRA_SIGN_UP = gql`
  mutation jiraSignUp($authCode: String!) {
    jiraSignUp(authCode: $authCode) {
      _id
    }
  }
`;
const JOIN_EVENT = gql`
  mutation joinEvent($eventId: String!, $username: String!) {
    joinEvent(eventId: $eventId, username: $username) {
      _id
      title
      text
      date
      category
      participants {
        name
        username
        profileImg
      }
    }
  }
`;
const LEAVE_EVENT = gql`
  mutation leaveEvent($eventId: String!, $username: String!) {
    leaveEvent(eventId: $eventId, username: $username) {
      _id
      title
      text
      date
      category
      participants {
        name
        username
        profileImg
      }
    }
  }
`;
const JIRA_LOGIN = gql`
  mutation jiraLogin($authCode: String!) {
    jiraLogin(authCode: $authCode)
  }
`;
const DELETE_EVENT = gql`
  mutation deleteEvent($_id: String!) {
    deleteEvent(_id: $_id) {
      _id
    }
  }
`;
const ADD_COMMENT = gql`
  mutation addComment($eventId: String!, $text: String!) {
    addComment(eventId: $eventId, text: $text) {
      _id
      commenter {
        username
        showName
        profileImg
      }
      eventId
      text
    }
  }
`;
const ADD_TO_EVENT_WATCHERS = gql`
  mutation addToEventWatchers($notificationId: String!, $email: String!) {
    addToEventWatchers(notificationId: $notificationId, email: $email) {
      _id
      title
      watchedFrom
    }
  }
`;
const SEND_EMAIL = gql`
  mutation sendEmail($sender: String!, $eventCategory: String) {
    sendEmail(sender: $sender, eventCategory: $eventCategory)
  }
`;
const DELETE_USERS = gql`
  mutation deleteUsers($ids: [String!]!) {
    deleteUsers(ids: $ids) {
      _id
      name
      department
      admin
      approved
      email {
        emailStr
      }
    }
  }
`;
const UPDATE_USER = gql`
  mutation updateUser(
    $_id: String!
    $admin: Boolean!
    $approved: Boolean!
    $department: String!
  ) {
    updateUser(
      _id: $_id
      admin: $admin
      approved: $approved
      department: $department
    ) {
      _id
      name
      department
      admin
      approved
    }
  }
`;
const DELETE_EVENT_CATEGORY = gql`
  mutation deleteEventCategory($_id: String!) {
    deleteEventCategory(_id: $_id)
  }
`;
const SEND_EMAIL_ON_COMMENT = gql`
  mutation sendEmailOnComment($eventId: String!, $commenter: String!) {
    sendEmailOnComment(eventId: $eventId, commenter: $commenter)
  }
`;
const UPADATE_JIRA_TOKEN = gql`
  mutation updateJiraToken($token : String !) {
    updateJiraToken(token: $token)
  }
`
const CHANGE_PROFILE_IMG = gql`
 mutation changeProfileImg{
  changeProfileImg
 }
`
const CREATE_NOTIFICATIONS = gql`
  mutation createNotificationsMutation($data: iNotification!){
    createNotifications(data: $data)
  }
`
const UPDATE_NOTIFICATIONS = gql`
  mutation updateNotifications($ids: [ID!]!){
    updateNotifications(ids: $ids)
  }
`
export {
  CREATE_USER,
  LOGIN,
  LOGOUT,
  CONFIRM_EMAIL,
  CHECK_DUPLICATE_EMAIL,
  ANSWER_JOIN_REQUEST,
  RESET_PASSWORD,
  CONFIRM_PASSWORD,
  ADD_TODO_ITEM,
  DELETE_TODO_ITEM,
  EDIT_TODO_ITEM,
  CHANGE_PROFILE_IMG,
  GET_JIRA_AUTH_CODE,
  REFRESH_JIRA_TOKEN,
  UPDATE_JIRA_FILTER,
  CREATE_EVENT_CATEGORY,
  CREATE_EVENT,
  JIRA_SIGN_UP,
  CREATE_NOTIFICATIONS,
  JOIN_EVENT,
  LEAVE_EVENT,
  JIRA_LOGIN,
  DELETE_EVENT,
  ADD_COMMENT,
  ADD_TO_EVENT_WATCHERS,
  SEND_EMAIL,
  DELETE_USERS,
  UPDATE_USER,
  DELETE_EVENT_CATEGORY,
  SEND_EMAIL_ON_COMMENT,
  UPADATE_JIRA_TOKEN,
  UPDATE_NOTIFICATIONS
};
