import { useContext } from "react";
import {
  Avatar,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MeContext } from "../../MeContext";

const useStyles: any = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    border: "2px solid #F28B00",
    float: "left",
    "&:hover": {
      borderColor: "#0381C8",
      transition: "0.6s",
      cursor: "pointer",
    },
  },
}));
export default function DropDownList(props: any ) {
  const classes = useStyles();
  const me: any = useContext(MeContext);
  const matches : any = useMediaQuery('(max-width:950px)', { noSsr: true });

  return (
    <div className="avatar" onClick={() => window.location.replace("/my-profile")}>
      <Avatar
        alt="Fad"
        className={classes.avatar}
        src={me.profileImg}
      >
        {me.name[0] + me.name.split(" ")[1][0]}
      </Avatar>
      <p
        style={{
          float: "right",
          color: "white",
          fontFamily: "Sora",
          padding: "5px",
          cursor: "pointer",
          display: matches ? "none" : ""
        }}
      >
        {props.name}
      </p>
    </div>
  );
}
