import { useState, useContext } from "react";
import { List, ListItem, Avatar, Divider } from "@material-ui/core";
import "./../../../styles/todo.css";
import { MeContext } from "../../../MeContext";
import TodoCheckbox from "./TodoCheckbox";
import moment from "moment";
import { AddTodoButton } from "./AddTodoButton";
import styled, { keyframes } from "styled-components";

const StyledList = styled(List)`
  width: 100%;
  height: 100%;
  overflow: auto; 
`

const Strike = keyframes`
  0%   { width : 0; }
  100% { width: 79%; }
`;
const TodoText = styled.p`
  font-size: 14px;
  width: 90%;
  color: black;
  text-align: left;
  word-break: break-word;
  margin-left: 20px;
  font-display: swap;
`;
const CheckedTodoText = styled.p`
  font-size: 14px;
  width: 90%;
  color: grey;
  text-align: left;
  margin-left: 20px;
  word-break: break-word;
  font-display: swap;
  @media (max-width: 960px) {
    font-size: 2.8vw;
  }
  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 10%;
    width: 20%;
    height: 1px;
    background: silver;
    animation-name: ${Strike};
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`;

export default function Todo(props: any) {
  const me: any = useContext(MeContext);
  const [editTextState, setEditTextState] = useState(false);
  const [tempTodoItem, setTempTodoItem] = useState({});
  const [todaysDate, setTodaysDate] = useState(moment().format("YYYY-MM-DD"));
  const regex = /[A-Z]{2,6}-[0-9]{2,10}/g;
  const urlify = (text: string) => {
    return text.replace(regex, (url) => {
      return `<a href="https://sauterheld.atlassian.net/browse/${url}" target="_blank">${url}</a>`;
    });
  };
  const resetState = () => {
    setEditTextState(false);
  };
  const handleEditIcon = (todo: any) => {
    setTempTodoItem(todo);

    setEditTextState(!editTextState);
  };
  const isDisabled: Function = (): string => {
    const todoDate: any = localStorage.getItem("todo_date");
    if (todoDate < todaysDate) return "none";
    if (props.todo.username === me.email.emailStr) return "";
    return "none";
  };
  return (
    <div className="todo-card">
      <div className="todo-card-header">
        <Avatar style={{ float: "left" }} src={props.todo.userImg} />
        <p className="todo-card-header-name">{props.todo.name}</p>
        <div style={{ position: "absolute", right: "1px" }}>
          <AddTodoButton
            todo={props.todo}
            todos={props.todos}
            editModeState={editTextState}
            resetEditModeState={resetState}
            todoItem={tempTodoItem}
            resetTableCells={() => {}}
          />
        </div>
      </div>
      <div className="todo-card-body">
        <StyledList>
          {props.todo.todos.map((todo: any, key: any) => {
            return (
              <div key={key} className="todo-content">
                <ListItem style={{ padding: "10px" }} key={key}>
                  <TodoCheckbox
                    todo={todo}
                    myEmail={me.email.emailStr}
                    todoUsername={props.todo.username}
                    onDoubleClick={(e: any) => alert("asd")}
                  />
                  {todo.checked ? (
                    <CheckedTodoText
                      dangerouslySetInnerHTML={{
                        __html: urlify(todo.text).trim(),
                      }}
                    />
                  ) : (
                    <TodoText
                      dangerouslySetInnerHTML={{
                        __html: urlify(todo.text).trim(),
                      }}
                    />
                  )}

                  <i
                    className="fas fa-pen"
                    style={{
                      right: 4,
                      display: isDisabled(),
                    }}
                    id="edit-todo-icon"
                    onClick={(e: any) => handleEditIcon(todo)}
                  ></i>
                </ListItem>
                <Divider variant="fullWidth" style={{backgroundColor: "#ebeef0"}} />
              </div>
            );
          })}
        </StyledList>
      </div>
    </div>
  );
}
