import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import {
  GET_JIRA_AUTH_CODE,
  JIRA_LOGIN,
  JIRA_SIGN_UP,
} from "../../graphql/mutations";

export default function JiraCallback() {
  const params: any = window.location.search;
  const urlParams = new URLSearchParams(params);

  const [getJiraAccessToken] = useMutation(GET_JIRA_AUTH_CODE, {
    variables: { authCode: urlParams.get("code") },
    onError: () => {
      window.location.replace("/");
    },
  });
  const [jiraSignUp] = useMutation(JIRA_SIGN_UP, {
    variables: { authCode: urlParams.get("code") },
    onError: () => {
      window.location.replace("/");
    },
  });
  const [authorized, setAuthorized] = useState(false);
  const [jiraLogin] = useMutation(JIRA_LOGIN, {
    onError: () => {
      window.location.replace("/");
    },
  });

  const getAccess: any = async () => {
    const getAccToken: any = await getJiraAccessToken();
    if (getAccToken.data.getJiraAccessToken.accToken === "") {
      setAuthorized(false);
      alert("Ihre Jira konnte nicht verbuden werden");
    } else {
      setAuthorized(true);
      localStorage.removeItem("jira_callback_accToken");
      localStorage.setItem(
        "jira_auth",
        JSON.stringify(getAccToken.data.getJiraAccessToken)
      );
      window.location.replace("/");
    }
  };
  const signUpJira = async () => {
    try {
      const signUp: any = await jiraSignUp();
      if (signUp.data.jiraSignUp === null)
        alert("Fehler: bitte Jira Profile vollständig ausfüllen");
      if (signUp.data.jiraSignUp._id) {
        alert(
          "Benutzer wurde erfolgreich angelegt warten sie bis Sie vom Admin genehmigt werden "
        );
        window.location.replace("/");
      } else {
        console.log("Fehler: " + signUp.data.jiraSignUp);
        alert("Fehler beim anmelden");
      }
    } catch (e: any) {
      console.log(e);
      alert('Fehler beim Registrieren');
    }
  };
  const loginJira = async () => {
    try {
      const res: any = await jiraLogin({
        variables: { authCode: urlParams.get("code") },
      });
      console.log(res.data);
      localStorage.setItem("token", res.data.jiraLogin);
      if (res.data.jiraLogin === "")
        alert("Wir können uns nicht bei Ihrem Konto anmelden");
    } catch (e: any) {
      console.warn(e);
      alert("Leider ist ein Problem aufgetreten");
    }
    localStorage.removeItem("jira_login");
    window.location.replace("/");
  };

  useEffect(() => {
    const jira_sign_up: any = localStorage.getItem("jira_sign_up");
    const jira_login: any = localStorage.getItem("jira_login");
    const jira_callback_accToken: any = localStorage.getItem(
      "jira_callback_accToken"
    );

    if (JSON.parse(jira_login) === true) loginJira();
    if (JSON.parse(jira_sign_up) === true) signUpJira();
    if (JSON.parse(jira_callback_accToken)) getAccess();
  }, []);

  return (
    <div>
      {authorized ? <p>jira is connected</p> : <p>not connected</p>}
      <Link to="/">wieder zu Main Seite</Link>
    </div>
  );
}
