import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Divider,
  Avatar,
  FormControlLabel,
  Container,
  Grid,
  Switch,
  Box,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
  CREATE_EVENT,
  CREATE_NOTIFICATIONS,
  SEND_EMAIL,
} from "../../../graphql/mutations";
import { useParams } from "react-router";
import { EditorState } from "draft-js";
import moment from "moment";
import { MeContext } from "../../../MeContext";
import EventRichTextEditor from "./EventRichTextEditor";
import { convertToRaw } from "draft-js";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function AddEventDialog(props: any) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [withTime, setWithTime] = useState(false);
  const [withToDate, setWithToDate] = useState(true);
  const [inpTitle, setInpTitle] = useState("");
  const [img, setImg] = useState("");
  const [createEvent] = useMutation(CREATE_EVENT);
  const [sendEmail] = useMutation(SEND_EMAIL);
  const { eventCategory }: any = useParams();
  const [createNotifications] = useMutation(CREATE_NOTIFICATIONS);
  const [withEmail, setWithEmail] = useState(true);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [imgLoading, setImgLoading] = useState(false);

  const me: any = useContext(MeContext);
  const TextFieldStyle = {
    width: "100%",
    margin: "10px 0 40px 0",
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateEvent = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    if (
      inpTitle === "" ||
      rawContent.blocks[0].text === "" ||
      img === "" ||
      moment(fromDate).format() === ""
    )
      alert("Bitte alle Felder ausfüllen");
    else {
      setOpen(false);
      const rawContentStr = JSON.stringify(convertToRaw(contentState));
      await createEvent({
        variables: {
          data: {
            title: inpTitle,
            text: rawContentStr,
            img: img,
            category: eventCategory,
            date: moment(fromDate).format(withTime ? "" : "YYYY-MM-DD"),
            toDate: !withToDate
              ? moment(new Date(1000, 0, 0)).format("YYYY-MM-DD")
              : moment(toDate).format(withTime ? "" : "YYYY-MM-DD"),
          },
        },
      });

      await createNotifications({
        variables: {
          data: {
            title: `${me.name} hat ein Event in der Kategorie ${eventCategory} angelegt`,
            name: me.name,
            email: me.email.emailStr,
            profileImg: me.profileImg,
            url: `/events/${eventCategory}`,
          },
        },
      });
      try {
        if (withEmail)
          await sendEmail({
            variables: {
              sender: me.email.emailStr,
              eventCategory: eventCategory,
            },
          });
      } catch (e: any) {
        console.log(e);
      }
      window.location.reload();
    }
  };
  const handleUploadImg = (e: any) => {
    let img: any = e.target;
    let reader: FileReader = new FileReader();
    reader.onload = (e) => {
      let dataUrl: any = reader.result;
      var fileSize = img.files[0].size / 1024 / 1024;
      if (fileSize > 1) alert("das Bild ist groß");
      else setImg(dataUrl);
    };
    reader.readAsDataURL(img.files[0]);
  };
  const getRandomPhoto: any = async () => {
    setImg("");
    setImgLoading(true);
    if (inpTitle === "")
      alert(
        "Bitte Titel hinzufügen, um passendes Bild zu bekommen z.B 'food, pizza' oder 'meeting'  "
      );
    else {
      try {
        const req = await fetch(
          `https://source.unsplash.com/800x600/?${inpTitle}`
        );
        const res = await req;

        setImg(res.url);
      } catch (e: any) {
        alert("Fehler beim Abrufen des Bildes");
      }
    }
    setImgLoading(false);
  };

  const fromDateOnChange = (e: any, isTime: boolean) => {
    if (isTime) {
      let time = e.target.value;
      let newDate = new Date(toDate);
      time = time.split(":");
      newDate.setHours(time[0]);
      newDate.setMinutes(time[1]);
      setFromDate(newDate);
    } else setFromDate(e.target.value);
  };

  const toDateOnChange = (e: any, isTime: boolean) => {
    if (isTime) {
      let time = e.target.value;
      let newDate = new Date(toDate);
      time = time.split(":");
      newDate.setHours(time[0]);
      newDate.setMinutes(time[1]);
      setToDate(newDate);
    } else setToDate(e.target.value);
  };

  return (
    <>
      <Button variant="text" onClick={() => setOpen(true)} color="primary">
        <PostAddIcon /> Event anlegen
      </Button>

      <Dialog open={open} onClose={(e) => setOpen(false)}>
        <div
          style={{
            width: "550px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <DialogTitle>Event anlegen</DialogTitle>
          <hr />
          <DialogContent>
            <Container>
              {imgLoading ? (
                <LinearProgress />
              ) : (
                <Avatar
                  variant="square"
                  alt="event-logo"
                  src={img}
                  style={{
                    width: "350px",
                    height: "180px",
                    borderRadius: "5px",
                    float: "left",
                  }}
                />
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "25px",
                }}
              >
                <Button component="label">
                  <AddAPhotoIcon />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleUploadImg(e)}
                  />
                </Button>
                <Tooltip title="Random Foto vom Titel">
                  <Button onClick={() => getRandomPhoto()}>
                    <AutorenewIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="zurücksetzen">
                  <Button onClick={(e) => setImg("")}>
                    <RefreshIcon />
                  </Button>
                </Tooltip>
              </div>
            </Container>
            <TextField
              label="Titel"
              style={TextFieldStyle}
              value={inpTitle}
              onChange={(e) => setInpTitle(e.target.value)}
            />
            <br />
            <Box
              height={100}
              border={1}
              borderColor="grey.500"
              borderRadius={4}
              style={{ overflow: "auto" }}
            >
              <EventRichTextEditor
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </Box>
            <br />
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  variant="outlined"
                  style={{ width: "170px" }}
                  value={moment(fromDate).format("YYYY-MM-DD")}
                  onChange={(e: any) => fromDateOnChange(e, false)}
                  label={"Von Datum"}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  style={{
                    textTransform: "none",
                    display: withTime === false ? "" : "none",
                  }}
                  onClick={() => setWithTime(true)}
                >
                  Zeit einstellen
                </Button>
                <TextField
                  type="time"
                  variant="outlined"
                  style={{ width: "150px", display: withTime ? "" : "none" }}
                  value={moment(fromDate).format("HH:mm")}
                  onChange={(e: any) => fromDateOnChange(e, true)}
                  label={"Zeit"}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  style={{ height: "100%", display: withTime ? "" : "none" }}
                  onClick={() => setWithTime(false)}
                >
                  <CloseIcon>X</CloseIcon>
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  variant="outlined"
                  style={{ width: "170px" }}
                  value={moment(toDate).format("YYYY-MM-DD")}
                  onChange={(e: any) => toDateOnChange(e, false)}
                  label={"Bis Datum"}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="time"
                  variant="outlined"
                  style={{ width: "150px", display: withTime ? "" : "none" }}
                  value={moment(toDate).format("HH:mm")}
                  onChange={(e: any) => toDateOnChange(e, true)}
                  label={"Zeit"}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={withEmail}
                  onChange={(e) => setWithEmail(e.target.checked)}
                />
              }
              label="E-Mail an alle Benutzer senden"
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Abrechen
            </Button>
            <Button color="primary" onClick={handleCreateEvent}>
              Anlegen
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
