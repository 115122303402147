import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, Avatar, TextField, Button, Tooltip, CircularProgress, makeStyles, DialogTitle } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { CREATE_EVENT_CATEGORY } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles({
  img: {
    width: '90%',
    height: '200px',
    left: '5%',
    right: '5%',
    relative: 'absolute',
    borderRadius: '5px'
  },
  dialogContent: {
    width: '500px',

  },
  titleInp: {
    width: '100%',
  }
})

export default function AddEventCategoryDialog(props: any) {
  const classes = useStyles();
  const [img, setImg] = useState("");
  const [imgLoading, setImgLoading] = useState<boolean>(false);
  const [inpTitle, setInpTitle] = useState("");
  const [createEventCategory] = useMutation(CREATE_EVENT_CATEGORY);

  const handleCreateEventCategory = async () => {
    if (inpTitle === "") alert("Bitte Titel eingeben");
    else {
      await createEventCategory({ variables: { title: inpTitle, img: img } });
      props.refetch();
      props.handleCloseDialog();
    }
  }
  const handleUploadImg = (e: any) => {
    let img: any = e.target;
    let reader: FileReader = new FileReader();
    reader.onload = (e) => {
      let dataUrl: any = reader.result;
      var fileSize = img.files[0].size / 1024 / 1024;
      if (fileSize > 1) alert("das Bild ist groß");
      else setImg(dataUrl);
    };
    reader.readAsDataURL(img.files[0]);
  };
  const getRandomPhoto: any = async () => {
    setImg("");
    if (inpTitle === "")
      alert("Bitte Titel hinzufügen um passendes Bild zu bekommen z.B 'food, pizza' oder 'meeting'  ");
    else {
      try {
        setImgLoading(true);
        const req = await fetch(
          `https://source.unsplash.com/800x600/?${inpTitle}`
        );
        const res = await req;

        setImg(res.url);
        setImgLoading(false);
      } catch (e: any) {
        alert('Fehler beim Abrufen des Bildes')
      }
    }
  };
  return (
    <Dialog open={props.open} onClose={props.handleCloseDialog}>
      <DialogTitle style={{ textAlign: 'center' }}>Kategorie</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {imgLoading ? <CircularProgress /> : <Avatar variant="square" src={img} className={classes.img} />}
        <br />

        <TextField label="Titel" className={classes.titleInp} variant="outlined" value={inpTitle} onChange={(e: any) => setInpTitle(e.target.value)} />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <Button variant="contained" component="label">
            Bild hochladen{" "}
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleUploadImg(e)}
            />
          </Button>
          <Tooltip title="zurücksetzen">
            <Button onClick={(e) => setImg("")}>
              <RefreshIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Random Foto">
            <Button onClick={() => getRandomPhoto()}>
              <AutorenewIcon />
            </Button>
          </Tooltip>
        </div>

        <DialogActions>
          <Button color="secondary" onClick={props.handleCloseDialog}>Abbrechen</Button>
          <Button onClick={handleCreateEventCategory}>Anlegen</Button>

        </DialogActions>
      </DialogContent>
    </Dialog>

  )
}