import { useState, useEffect } from "react";
import Select from "react-select";
import { useQuery } from "@apollo/react-hooks";
import { GET_JIRA_ISSUES } from "../../../graphql/queries";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { Collapse } from "@material-ui/core";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#A8A8A8" : "",
    color: state.isSelected ? "#FFFF" : "",
    padding: 20,
    fontSize: "15px",
    borderRadius: "0.2rem",
    borderBottom: "1px solid grey",
    cursor: "pointer",
    "&:hover": {
      background: "#A8A8A8",
      transition: "0.2s",
      color: "#FFFF",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    display: "flex",
    borderRadius: "0.3rem",
    position: "relative",
    width: "100%",
    height: "auto",
    border: "1px solid grey",
    background: "rgba(255, 255, 255, 0.2)",
    marginBottom: "20px",
    cursor: "pointer",
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    position: "fixed",
    padding: "10px",
    height: "500px",
    width: "26%",
    top: "25%",
    left: "37%",
    right: "37%",
    backgroundColor: "#FFFF",
    bottom: "30%",
    minHeight: "100px",
    overflow: "auto",
    zIndex: 1,
    border: "1px solid grey",
    borderRadius: "0.5rem",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: "sans-serif",
    fontSize: "14px",
  }),
};

export default function JiraIssuesSelector(props: any) {
  const [showAlert, setShowAlert] = useState(false);
  const { data, loading, error } = useQuery(GET_JIRA_ISSUES, {
    variables: { jiraFilter: props.jiraFilter },
  });
  const [text, setText] = useState({
    value: "",
    label: "Vorgang von Jira hinzufügen...",
  });

  const setOptions: any = (issues: any) => {
    const options = [];
    for (let issue of issues) {
      options.push({ value: issue.id, label: issue.id + "   " + issue.title });
    }
    return options;
  };

  const handleOnChange = (e: any) => {
    props.onChangeHandle(e);
  };
  useEffect(() => {
    setTimeout(() => setShowAlert(false), 3000);
  }, [showAlert]);

  if (loading) return <Select isDisabled placeholder="loading..." isLoading/>;
  if (error) {
    console.log(error);
    return <Select isDisabled placeholder="Jira ist nicht erreichbar" />;
  }
  return (
    <div style={{ height: "auto" }}>
      <Collapse in={showAlert}>
        <Alert style={{ width: "100%" }} severity="error">
          Error Jira: bitte Filter richtig setzen oder nochmal einloggen unter{" "}
          <Link to="/my-profile">my profile</Link>
        </Alert>
      </Collapse>
      <Select
        className="basic-single"
        classNamePrefix="select"
        options={setOptions(data.getJiraIssues)}
        defaultValue={text}
        onChange={handleOnChange}
        maxMenuHeight={680}
        isLoading={loading}
        isSearchable
        noOptionsMessage={() => "kein Vorgänge vorhanden "}
        styles={customStyles}
      />
    </div>
  );
}
