import React, { useState, useEffect } from "react";
import "./../../styles/login.css";
import { LOGIN } from "../../graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import Logo from "./../../img/logo.png";
import Alert from "@material-ui/lab/Alert";
import validator from "validator";

export default function Login() {
  const [inpUsername, setInpUsername] = useState("");
  const [inpPassword, setInpPassword] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [login] = useMutation(LOGIN);
  const [errAlert, setErrorAlert] = useState({
    showAlert: false,
    message: "",
  });

  const loginHandle = async () => {

    if(inpUsername === "" || inpPassword === "") setErrorAlert({showAlert: true, message: "Bitte alle Felder ausfüllen"})
    if(emailErr) return false
    else {
    try {
      const res = await login({
        variables: {
          login: { email: inpUsername.toLowerCase(), password: inpPassword },
        },
      });

      if (res.data.login === "false") {
        setErrorAlert({ showAlert: true, message: "Fehler: Sie müssen entweder Ihre E-mail bestätigen oder auf die Adminstratoreinnahme warten" });
        return false;
      }
      const token: string = res.data.login;
      localStorage.setItem("token", token);
      window.location.reload();
    } catch (e: any) {
      setErrorAlert({ showAlert: true, message: e.message });
    }
  }
  };
  
  const errAlertOnClose = () => {
    setErrorAlert({ showAlert: false, message: "" });
  };

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.keyCode === 13) {
        loginHandle();
      }
    };
    document.addEventListener("keypress", onEnter);
    return () => document.removeEventListener("keypress", onEnter);
  });

  return (
    <div className="login-main-container">
      {errAlert.showAlert ? (
        <Alert severity="error" onClose={errAlertOnClose} style={{ position: "fixed" }}>
          {errAlert.message}
        </Alert>
      ) : (
        ""
      )}
      <img src={Logo} width="215" height="135" alt="logo"></img>{" "}
      <div className="login-box">
        <h1>Anmelden</h1>
        <hr></hr>
        <div className="textBox">
          <i className="fas fa-user"></i>
          <TextField
            autoFocus
            type="email"
            style={{ width: "80%" }}
            placeholder="E-Mail"
            value={inpUsername}
            error={emailErr}
            helperText={emailErr ? "ungültige E-Mail Adresse" : ""}
            onChange={(e) => setInpUsername(e.target.value)}
            onBlur={() => !validator.isEmail(inpUsername) && inpUsername !== "" ? setEmailErr(true) : setEmailErr(false)}
          />
        </div>
        <div className="textBox">
          <i className="fas fa-lock"></i>
          <TextField
            type="password"
            placeholder="Passwort"
            style={{ width: "80%" }}
            value={inpPassword}
            onChange={(e) => setInpPassword(e.target.value)}
          />
        </div>
        <br />

        <Button
          onClick={loginHandle}
          value="Sign in"
          className="btn"
          style={{
            backgroundColor: "#f18a01",
            color: "white",
          }}
        >
          Einloggen
        </Button>
        <br />
        <Link
          to="/reset_password"
          style={{ color: "#f18a01", fontFamily: "sora" }}
        >
          <p>Passwort vergessen ?</p>
        </Link>
        <br />
        <p style={{ fontFamily: "sans-serif" }}>
          noch nicht registriert?{" "}
          <Link
            to="/signup"
            style={{
              color: "#e5e5e5	",
              fontFamily: "Arial, Helvetica, sans-serif",
              fontSize: "14px",
            }}
          >
            jetzt anmelden
          </Link>
        </p>
        <br />
      </div>
    </div>
  );
}
