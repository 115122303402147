import React, { useState, useContext } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import {
  Typography,
  DialogContent,
  Button,
  DialogActions,
  Grid,
  TextField,
  Avatar,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import { GET_EVENT_COMMENTS } from "../../../graphql/queries";
import { ADD_COMMENT, SEND_EMAIL_ON_COMMENT } from "../../../graphql/mutations";
import { client } from "../../../graphql/graphqlConnect";
import Picker from "emoji-picker-react";
import { MeContext } from "../../../MeContext";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "250px",
      height: "auto",
      padding: "5px",
    },
    dialogContent: {
      width: "500px",
      maxHeight: "400px",
    },
    dialogFooter: {
      height: "80px",
      marginRight: "20px",
    },
    showCommentsBtn: {
      textTransform: "none",
    },
    disabledShowCommentsBtn: {
      textTransform: "none",
    },
    commentText: {
      padding: "10px",
      fontFamily: "system-ui",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    inpComment: {
      width: "300px",
    },
  })
);

export default function EventComments(props: any) {
  const classes = useStyles();
  const [inpText, setInpText] = useState("");
  const [open, setOpen] = useState(false);
  const [emojieOpen, setEmojieOpen] = useState(false);
  const [chosenEmojie, setChosenEmojie] = useState(null);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [addComment] = useMutation(ADD_COMMENT);
  const me = useContext(MeContext);

  const [sendEmailOnComment] = useMutation(SEND_EMAIL_ON_COMMENT, {variables: {commenter: me.email.emailStr ,eventId: props.event._id }});
  const { loading, error, data } = useQuery(GET_EVENT_COMMENTS, {
    variables: { eventId: props.event._id },
  });
  const onEmojiClick = (event: any, emojieObject: any) => {
    setChosenEmojie(emojieObject);
    setInpText(inpText + emojieObject.emoji);
    setEmojieOpen(false);
  };
  const handleClose = () => {
    setEmojieOpen(false)
    setOpen(false);
  };
  const refetchComments = async () => {
    await client.refetchQueries({
      include: [GET_EVENT_COMMENTS],
    });
  };
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleAddComment = async () => {
    if (inpText === "") alert("bitte Text eingeben");
    else {
      const add = await addComment({
        variables: { eventId: props.event._id, text: inpText },
      });
      setInpText("");
      refetchComments();
      if(me.email.emailStr !== props.event.creator) {
        await sendEmailOnComment();
      }
    }
  };
  
  if (loading) return <CircularProgress />;
  if (error) return <p>error</p>;
  
  return (
    <div style={{ backgroundColor: "#E5E9FA", width: "auto", height: "auto" }}>
      <Button style={{ textTransform: "none" }} onClick={() => setOpen(true)}>
        {data.getEventComments.length === 0
          ? "noch keine Kommentare"
          : "Kommentare" + " (" + data.getEventComments.length + ")"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent className={classes.dialogContent}>
          <div>
            {data.getEventComments.map((comment: any, key: any) => {
              return (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar src={comment.commenter.profileImg}>
                      {comment.commenter.showName[0] +
                        comment.commenter.showName.split(" ")[1][0]}
                    </Avatar>
                  </Grid>
                  <Grid style={{ justifyContent: "left" }} item xs>
                    <Typography
                      style={{
                        margin: 0,
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {comment.commenter.showName}
                    </Typography>
                    <Typography style={{ textAlign: "left", fontSize: "15px" }}>
                      {comment.text}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <TextField
            variant="standard"
            placeholder="Kommentar schreiben"
            className={classes.inpComment}
            value={inpText}
            onClick={() => setEmojieOpen(false)}
            onChange={(e: any) => setInpText(e.target.value)}
          />
          <i
            className="fas fa-smile"
            onClick={() => setEmojieOpen(!emojieOpen)}
            style={{ cursor: "pointer", color: "grey" , padding: "5px", borderRadius: "5px" }}
          />
          <Picker
            onEmojiClick={onEmojiClick}
            pickerStyle={{ display: emojieOpen ? "" : "none", position: "fixed" }}
            disableSearchBar={true}
          />
          <Button
            variant="contained"
            style={{ textTransform: "none", display: emojieOpen ? "none" : "" }}
            onClick={() => handleAddComment()}
          >
            kommentieren
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
