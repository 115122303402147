import { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField } from "@material-ui/core";
import { ListItemText } from "@mui/material";
import styled from "styled-components";
import ImageSlider from "./ImageSlider";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import { useMutation } from "@apollo/client";
import {
  CHANGE_PROFILE_IMG,
  UPADATE_JIRA_TOKEN,
  UPDATE_JIRA_FILTER,
} from "../../graphql/mutations";
import FavouriteFilters from "../body/FavouriteFilters";

const StyledListItemText = styled(ListItemText)`
  padding: 5px;
`;
const StyledSliderContainer = styled.div`
  width: 40%;
  height: 300px;
`;
const steps = [
  "JIRA Token generieren",
  "Profilbild übernehmen",
  "Filter setzen (optional)",
  "feritg",
];

export default function NewProfileStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [jiraTokenInp, setJiraTokenInp] = useState("");
  const [error, setError] = useState(false);
  const [changeProfileImg] = useMutation(CHANGE_PROFILE_IMG, {
    onError: () => alert("Fehler bei der Änderung des Profilbildes"),
  });
  const [updateJiraToken] = useMutation(UPADATE_JIRA_TOKEN);
  const [updateJiraFilter] = useMutation(UPDATE_JIRA_FILTER);
  const [favFilterValue, setFavFilterValue] = useState("");

  const handleUpdateToken = async () => {
    try {
      await updateJiraToken({
        variables: { token: jiraTokenInp },
      });
    } catch (e: any) {
      console.log(e.message);
    }
  };
  const updateFilter = async () => {
    const modifiedString = favFilterValue.replace(/\"/g, `'`).trim();
    if (favFilterValue !== "")
      await updateJiraFilter({
        variables: { filter: modifiedString },
      });
  };
  const handleNext = async () => {
    if (activeStep === 3) setTimeout(() => window.location.reload(), 5000);
    if (activeStep === 0 && jiraTokenInp.length > 8) {
      await handleUpdateToken();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else setError(true);
    if (activeStep === 2) {
      await updateFilter();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleChangeProfileImg = async () => {
    try {
      await changeProfileImg();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (err) {
      console.log(err);
    }
  };
  const setFilterValue = (e: any) => {
    setFavFilterValue(e.value);
  };
  console.log(activeStep);
  return (
    <Box sx={{ width: "100%", marginTop: "6vh" }}>
      <Typography variant="h4" padding={"20px"} align={"center"}>
        Profile Einrichten
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label: string, index: any) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* ------------------------- first step------------------------- */}
      {activeStep === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <StyledListItemText>
            {" "}
            Klicken Sie auf den Link
            <a
              href="https://id.atlassian.com/manage-profile/security/api-tokens"
              target="_blank"
              style={{
                color: "#1281C8",
                fontSize: "15px",
                fontFamily: "Sora",
                padding: "10px",
              }}
            >
              Token generieren
            </a>
            ,um Ihr Jira-Token zu erhalten
          </StyledListItemText>
          <StyledSliderContainer>
            <ImageSlider />
          </StyledSliderContainer>
          <StyledListItemText>
            {" "}
            Bitte fügen Sie das generierte Token hier ein
          </StyledListItemText>
          <TextField
            variant="outlined"
            type="password"
            style={{ width: "400px" }}
            value={jiraTokenInp}
            onChange={(e: any) => setJiraTokenInp(e.target.value)}
            placeholder="generiertes Token einfügen..."
            helperText={error ? "Bitte gültiges Token eingeben!" : ""}
            onBlur={() => (jiraTokenInp.length < 10 ? setError(false) : "")}
            error={error}
          />
        </div>
      ) : (
        ""
      )}

      {activeStep === 1 ? (
        <div style={{ padding: "50px" }}>
          <Typography>Profilbild von JIRA übernehmen</Typography>
          <Button
            onClick={handleChangeProfileImg}
            style={{
              textTransform: "none",
              color: "#1281C8",
              margin: "20px 0 0 40%",
            }}
            variant="outlined"
          >
            <InsertPhotoIcon />
            <Typography style={{ fontSize: "14px" }}>
              Als Profilbild übernehmen
            </Typography>
          </Button>
        </div>
      ) : (
        ""
      )}
      {activeStep === 2 ? (
        <div style={{ padding: "20px", marginTop: "20px" }}>
          <Typography>Filter auswählen: </Typography>
          <FavouriteFilters filterValue={setFilterValue} />
        </div>
      ) : (
        ""
      )}
      {/* ------------------------- finish step------------------------- */}
      {activeStep === steps.length - 1 ? (
        <>
          <Typography variant="h6" sx={{ mt: 6, mb: 1 }}>
            Sie sind mit Ihren JIRA-Einstellungen fertig
          </Typography>
        </>
      ) : (
        <>
          <Button
            variant="contained"
            onClick={handleNext}
            style={{ position: "absolute", bottom: "4%", right: "3%" }}
          >
            {activeStep === steps.length - 1 ? "Fertig" : "Weiter"}
          </Button>
        </>
      )}
    </Box>
  );
}
