import React, {useState} from 'react';
import MainToolbar from './components/header/Toolbar';
import  "./styles/main.css";

function App() {
  const [departments, setDepartments] = useState([]);
  const departmentsOnchange = (value : any ) => {
    setDepartments(value);
  }
  return (
    <div className="App">
      <MainToolbar departmentsOnchange={departmentsOnchange} department={departments}/>
    </div>
  );
}
export default App;
