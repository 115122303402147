import React, { useState, useContext, useEffect } from "react";
import {
  AppBar,
  CssBaseline,
  makeStyles,
  useTheme,
  IconButton,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import Logo from "./../../img/logo.png";
import DropDownList from "./DropDownList";
import { MeContext } from "../../MeContext";
import Notification from "./Notification";
import makeAnimated from "react-select/animated";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EventIcon from "@material-ui/icons/Event";
import "./../../styles/header.css";
import Select from "react-select";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DEPARTMENTS } from "../../graphql/queries";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Main from "../body/Main";
import EventCategories from "../body/events/EventCategories";
import { LOGOUT } from "../../graphql/mutations";
import PeopleIcon from "@material-ui/icons/People";
import { Profile } from "../profile/Profile";
import { UserManagement } from "../body/UserManagement";

const drawerWidth = 225;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    backgroundColor: "#474644",
    width: "100%",
    height: "auto",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    maxWidth: "135px",
    minHeight: "70px",
  },
  hide: {
    display: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    width: "100%",
    height: "auto",
  },
  rightSide: {
    position: "absolute",
    right: "0",
    display: "flex",
    padding: "10px",
    alignItems: "center",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#474644",
    border: "none"
  },
  drawerHeader: {
    display: "flex",
    height: "9.9vh",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "#484644",
    borderBottom: "0.010rem solid grey",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

interface DefValue {
  value: string;
  label: string;
}
export default function MainToolbar(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const me: any = useContext(MeContext);
  const animatedSelect = makeAnimated();
  const [open, setOpen] = useState(false);
  const [logout] = useMutation(LOGOUT);
  const [departmentDefValue, setDepartmentDefValue] = useState<DefValue[]>([]);
  const { loading, error, data }: any = useQuery(GET_DEPARTMENTS);

  const [renderOptions, setRenderOptions] = useState({
    event: false,
    todo: false,
    profile: false,
    userManagement: false,
  });
  const selectOptions = (): { value: string; label: string }[] => {
    const departments: any = [...data.departments];

    let options: any = [
      { value: "Alle Abteilungen", label: "Alle Abteilungen" },
    ];

    for (const department of departments) {
      options.push({ value: department.name, label: department.name });
    }
    return options;
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logoutHandle = async () => {
    const res = await logout();
    localStorage.clear();
    window.location.reload();
  };
  const departmentChange = (e: any) => {
    const options = [...e];
    if (options.length === 0)
      localStorage.setItem(
        "department_selects",
        JSON.stringify([me.department])
      );
    else {
      const departments: any = [];
      options.map((item) => {
        departments.push(item.value);
      });
      localStorage.setItem("department_selects", JSON.stringify(departments));
      props.departmentsOnchange(departments);
    }
  };
  useEffect(() => {
    if (me.admin) {
      const savedDepartmentVal: any =
        localStorage.getItem("department_selects");
      const parsedDefValue: any = JSON.parse(savedDepartmentVal);
      const departments: any = [];

      if (parsedDefValue === null) {
        setDepartmentDefValue([{ value: me.department, label: me.department }]);
        localStorage.setItem(
          "department_selects",
          JSON.stringify([me.department])
        );
      } else {
        const defDepartments = [...departmentDefValue];
        for (let department of parsedDefValue) {
          defDepartments.push({ value: department, label: department });
          departments.push(department);
        }
        if (parsedDefValue.length < 1) {
          setDepartmentDefValue([
            { value: me.department, label: me.department },
          ]);
          localStorage.setItem(
            "department_selects",
            JSON.stringify([me.department])
          );
        }
        setDepartmentDefValue(defDepartments);
        localStorage.setItem("department_selects", JSON.stringify(departments));
      }
    } else {
      const dep: any = [me.department];
      localStorage.setItem("department_selects", JSON.stringify(dep));
    }
  }, []);

  useEffect(() => {
    setRenderOptions({
      todo: true,
      event: false,
      profile: false,
      userManagement: false,
    });
  }, []);
  const renderComponent = (value: any) => {
    if (value === "todo")
      setRenderOptions({
        todo: true,
        event: false,
        profile: false,
        userManagement: false,
      });
    if (value === "profile")
      setRenderOptions({
        todo: false,
        event: false,
        profile: true,
        userManagement: false,
      });
    if (value === "event")
      setRenderOptions({
        todo: false,
        event: true,
        profile: false,
        userManagement: false,
      });
    if (value === "user-management")
      setRenderOptions({
        todo: false,
        event: false,
        profile: false,
        userManagement: true,
      });
  };
  if (loading) return <p></p>;
  if (error) return <p>Error...</p>;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <a href="/">
            <img src={Logo} className={classes.logo} />
          </a>
          <div className="search-box" style={{ display: me.admin ? "" : "none" }}>
            <Select
              component={animatedSelect}
              options={selectOptions()}
              isMulti
              defaultValue={departmentDefValue}
              isDisabled={me.admin ? false : true}
              isClearable={true}
              onChange={departmentChange}
              styles={{
                option: (base) => ({
                  ...base,
                  fontFamily: "'Sora', sans-serif",
                }),
                multiValueLabel: () => ({
                  fontFamily: "'Sora', sans-serif",
                  fontSize: "14px",
                  padding: "2px",
                }),
              }}
            />
          </div>
          <div className={classes.rightSide}>
            <DropDownList name={me.name} />
            <Notification />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} style={{color: "white", height: "50px"}}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>

        <List>
          <ListItem
            button
            style={{
              backgroundColor: renderOptions.todo ? "#fbfbfd" : "",
              color: renderOptions.todo ? "#474644" : "white" ,
              borderRadius: "2px",
              marginTop: "20px",
              height:  renderOptions.todo ? "85px" : "75px"
            }}
            onClick={() => renderComponent("todo")}
          >
            <b style={{display: renderOptions.todo ? "" : "none"}} className="sidebar-list-item-top" />
            <b style={{display: renderOptions.todo ? "" : "none"}} className= "sidebar-list-item-bottom"/>
            <ListItemIcon>
              <AssignmentTurnedInIcon style={{ color: "#F28B00" }} />
            </ListItemIcon>
            <ListItemText primary={"Todos"} />
          </ListItem>
          <ListItem
            button
            style={{
              backgroundColor: renderOptions.event ? "#fbfbfd" : "",
              color: renderOptions.event ? "#474644" : "white", 
              borderRadius: "2px",
              height:  renderOptions.event ? "85px" : "75px"
            }}
            onClick={() => renderComponent("event")}
          >
            <b style={{display: renderOptions.event ? "" : "none"}} className="sidebar-list-item-top" />
            <b style={{display: renderOptions.event ? "" : "none"}} className= "sidebar-list-item-bottom"/>
            <ListItemIcon>
              <EventIcon style={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText>Events</ListItemText>
          </ListItem>
          <ListItem
            button
            style={{
              backgroundColor: renderOptions.profile ? "#fbfbfd" : "",
              color: renderOptions.profile ? "#474644" : "white", 
              borderRadius: "20px 0 0 20px",
              width: "100%",
              position: "relative",
              right: "0",
              height:  renderOptions.profile ? "85px" : "75px"
            }}
            onClick={() => renderComponent("profile")}
          >
            <b style={{display: renderOptions.profile ? "" : "none"}} className="sidebar-list-item-top" />
            <b style={{display: renderOptions.profile ? "" : "none"}} className= "sidebar-list-item-bottom"/>
            <ListItemIcon>
              <AccountBoxIcon style={{ color: "#1281C8" }} />
            </ListItemIcon>
            <ListItemText primary={"Profile"} />
          </ListItem>
          <ListItem
            style={{
              display: me.admin ? "" : "none",
              backgroundColor: renderOptions.userManagement ? "#fbfbfd" : "",
              color: renderOptions.userManagement ? "#474644" : "white", 
              borderRadius: "2px",
              height:  renderOptions.userManagement ? "85px" : "75px"
            }}
            button
            onClick={() => renderComponent("user-management")}
          >
            <b style={{display: renderOptions.userManagement ? "" : "none"}} className="sidebar-list-item-top" />
            <b style={{display: renderOptions.userManagement ? "" : "none"}} className= "sidebar-list-item-bottom"/>
            <ListItemIcon>
              <PeopleIcon style={{ color: "grey" }} />
            </ListItemIcon>
            <ListItemText primary={"Benutzerverwaltung"} />
          </ListItem>
          <ListItem button onClick={() => logoutHandle()}>
            <ListItemIcon>
              <ExitToAppIcon style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText style={{color: "white"}} primary={"Ausloggen"} />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div style={{ height: "auto" }} />
        {renderOptions.event ? (
          <EventCategories />
        ) : renderOptions.todo ? (
          <Main departments={props.departments} />
        ) : renderOptions.userManagement ? (
          <UserManagement />
        ) : (
          <Profile />
        )}
      </main>
    </div>
  );
}
