import { Editor, EditorState, RichUtils } from "draft-js";
import {Stack, IconButton} from '@mui/material';
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import { Divider } from "@material-ui/core";
import styled from "styled-components";

const Div = styled.div`
    font-family: sans-serif;
    padding: 5px;
`

export default function EventRichTextEditor({editorState, setEditorState}: any) {
  const onChange = (editorState: any) => setEditorState(editorState);

  const handleKeyCommand = (command: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onUnderlineClick = () => onChange( RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  const onItalicClick = () => onChange( RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  const onBoldClick = () =>onChange( RichUtils.toggleInlineStyle(editorState, "BOLD"));

  return (
    <Div>
      <Stack direction="row" spacing={1}>
        <IconButton aria-label="Bold" style={{padding: "0px"}}>  <FormatBoldIcon onClick={onBoldClick} /></IconButton>
        <IconButton aria-label="Italic">  <FormatItalicIcon onClick={onItalicClick} /></IconButton>
        <IconButton aria-label="Underline"><FormatUnderlinedIcon onClick={onUnderlineClick}/></IconButton>
      </Stack>
      <Divider />
      <Editor
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        onChange={onChange}
      />
    </Div>
  );
}
